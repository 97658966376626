import {
  USERS_LIST_REQUEST,
  USERS_LIST_SUCCESS,
  CLEAR_ERRORS,
  USERS_LIST_FAIL,
  CHANGEPASSWORD_REQUEST,
  CHANGEPASSWORD_SUCCESS,
  CHANGEPASSWORD_FAIL,
} from "../Constants/UserConstants";
import axios from "axios";
import { BASE_URL } from "../Utils";
export const UserAction = (token) => async (dispatch) => {
  try {
    dispatch({
      type: USERS_LIST_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    };
    const { data } = await axios.get(
      `${BASE_URL}admin/user`,
      config
    );
    dispatch({ type: USERS_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: USERS_LIST_FAIL,
      payload: err.response,
    });
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

export const makeblockUnblock = async (id, values, token) => {
  try {
    let link = `${BASE_URL}admin/user/block/${id}`;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${token}`,
      },
    };
    const { data } = await axios.post(link, values, config);
    return data;
  } catch (err) {
    return {
      success: false,
      message: err,
    };
  }
};

export const changePassword = (formData, token) => async (dispatch) => {
  try {
    dispatch({ type: CHANGEPASSWORD_REQUEST });
    let link = `${BASE_URL}admin/auth/changePassword`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const data = await axios.put(link, JSON.stringify(formData), config);
    setTimeout(() => {
      dispatch({ type: CHANGEPASSWORD_SUCCESS, payload: data });
    }, 2000);
  } catch (err) {
    dispatch({ type: CHANGEPASSWORD_FAIL, payload: err.response });
  }
};
