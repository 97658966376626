import {
    ADD_RESELLER_REQUEST,
    ADD_RESELLER_SUCCESS,
    ADD_RESELLER_FAIL,
    ADD_RESELLER_RESET,

    CLEAR_ERRORS,

    UPDATE_RESELLER_REQUEST,
    UPDATE_RESELLER_SUCCESS,
    UPDATE_RESELLER_FAIL,
    UPDATE_RESELLER_RESET,

    DELETE_RESELLER_REQUEST,
    DELETE_RESELLER_SUCCESS,
    DELETE_RESELLER_FAIL,

    GET_RESELLER_REQUEST,
    GET_RESELLER_SUCCESS,
    GET_RESELLER_FAIL,
    GET_RESELLER_RESET,

    GET_RESELLER_DETAIL_REQUEST,
    GET_RESELLER_DETAIL_SUCCESS,
    GET_RESELLER_DETAIL_FAIL,
    GET_RESELLER_DETAIL_RESET,


 } from "../Constants/index";


export const ResellerListReducer = (state = {resellers:[]}, action) => {
    switch(action.type){
        case GET_RESELLER_REQUEST:
            return{
                loading:true,
                resellers:[]
            }
        case GET_RESELLER_SUCCESS:
            return{
                loading:false,
                resellers:action.payload.data
            }
        case GET_RESELLER_FAIL:
            return{
                loading:false,
                error:action.payload

            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error:null
            }
        default:
            return state
    }
}

export const ResellerDetailReducer = (state = {reseller:{}}, action) => {
    switch(action.type){
        case GET_RESELLER_DETAIL_REQUEST:
            return{
                loading:true,
                reseller:{}
            }
        case GET_RESELLER_DETAIL_SUCCESS:
            return{
                loading:false,
                reseller:action.payload.data
            }
        case GET_RESELLER_DETAIL_FAIL:
            return{
                loading:false,
                error:action.payload

            }
        case GET_RESELLER_DETAIL_RESET:
            return{
                ...state,
                reseller:{}
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error:null
            }
        default:
            return state
    }
}

export const AddResellerReducer = (state = {reseller : {}},action) => {
    switch(action.type){
        case ADD_RESELLER_REQUEST:
            return{
                ...state,
                loading:true
            }
        case ADD_RESELLER_SUCCESS:
            return{
                loading:false,
                success:true,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        case ADD_RESELLER_FAIL:
            console.log("dsdsds",action.payload.data)
            return{
                ...state,
                loading: false,
                error: action.payload.data.message,
            }
        case ADD_RESELLER_RESET:
            return{
                ...state,
                success:false
            }
        default:
            return state
    }
}

export const updateResellerReducer = (state = {},action) => {
    switch(action.type){
        case UPDATE_RESELLER_REQUEST:
            return{
                ...state,
                loading:true
            }
        case UPDATE_RESELLER_SUCCESS:
            return{
                ...state,
                loading:false,
                isUpdated:true
            }
        case UPDATE_RESELLER_FAIL:
            return{
                ...state,
                loading:false,
                error: action.payload.data.message,
            }
        case UPDATE_RESELLER_RESET:
            return{
                ...state,
                isUpdated:false
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state
    }
}

export const ResellerDeleteReducer = (state = {}, action) => {
    switch(action.type){
        case DELETE_RESELLER_REQUEST:
            return{
                ...state,
                loading:true
            }
        case DELETE_RESELLER_SUCCESS:
            return{
                loading:false,
                isDeleted:true
            }
        case DELETE_RESELLER_FAIL:
            return{
                loading:false,
                error:action.payload,
                isDeleted:false

            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error:null
            }
        default:
            return state
    }
}




