import React, { Fragment, useEffect, useState } from "react";
import { Dropdown, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import profile from "../../../../images/profile/profile.png";
import PageTitle from "../../../layouts/PageTitle";
import {
  vendorValidation,
  paymentValidation,
} from "../../../../services/validation";
// import useForm from "../../../../services/formHook";
// import VendorForm from "../../../pages/Vendors/form";

const AppProfile = ({ auth }) => {
  const dispatch = useDispatch();
  const [paymentDetails, setPaymentDetails] = useState({
    account: "asdadas",
    ifsc: "",
    holder: "",
  });
  const [paymentError, setPaymentError] = useState({});

  const [activeToggle, setActiveToggle] = useState("aboutMe");
  const { loading, error, vendor } = useSelector((state) => state.vendorDetail);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState("success");
  //     const [meta, setMeta] = useState({
  //             title : 'Add Vendor',
  
  //             submitTitle : 'Save',
  //             isSubmitting:false
  //         });
  //     const initialValues = {
  //         name:"",
  //         businessName_en:'',
  //         businessName_ar:"",
  //         email:'',
  //         phoneNumber:'',
  //         countryCode:'',
  //         coverPic:null,
  //         paymentDetails:{},
  //         address:"",
  //         //cate:[],
  //         cate:""

  //     }
  //     const {
  //      vendor:vendorData
  //  } = useSelector((state) => state.vendorDetail);
  //     const { categories } = useSelector((state) => state.categories);
  //     const { loading:updateLoading, error:updateError, isUpdated } = useSelector((state) => state.updateVendor)
  //     const { loading:paymentLoading, error:updatePaymentError, isPayment} = useSelector((state) => state.updateVendorPaymentReducer)
  //     const [isRefresh, setIsRefresh]  = useState(false)
  //     const {values, errors, handleChange, handleSubmit, handleUpdate} = useForm(initialValues,addMeditationForm,vendorValidation)
  //     function addMeditationForm(){
  //         const myForm = new FormData();
  //         myForm.set('name',values.name);
  //         myForm.set('businessName_en',values.businessName_en);
  //         myForm.set('businessName_ar',values.businessName_ar);
  //         myForm.set('address',values.address);
  //         myForm.set('email',values.email);
  //         myForm.set('mobile',values.phoneNumber);
  //         myForm.set('countryCode',values.countryCode.value);
  //         myForm.set('coverPic',values.coverPic);
  //         myForm.set('categoryType',values.cate.value);
  //         dispatch(updateVendor(myForm, values.id, auth.idToken));
  //     }

  //     const editCat = (e, data) => {
  //         e.preventDefault();
  //         setPaymentDetails({
  //           account : data.paymentDetails.account,
  //           ifsc : data.paymentDetails.ifsc,
  //           holder : data.paymentDetails.holder
  //         })
  //         setMeta({...meta,title:'Update Vendor',submitTitle:'Update'});
  //         setOpen(true)
  //     }

  //     const closeCreateCat = () => {
  //         setOpen(false)
  //     }

  //     const handlePaymentChange = (event) => {
  //       event.preventDefault();
  //         setPaymentDetails({...paymentDetails,[event.target.name] : event.target.value});
  //     }

  //     const handlePaymentSubmit = (event) => {
  //       event.preventDefault();
  //       setPaymentError(paymentValidation(paymentDetails));

  //       if (!paymentError.account && !paymentError.ifsc && !paymentError.holder){
  //         const myFormPayment = new FormData();
  //         myFormPayment.set('account',paymentDetails.account);
  //         myFormPayment.set('ifsc',paymentDetails.ifsc);
  //         myFormPayment.set('holder',paymentDetails.holder);
  //         dispatch(updatePaymentVendor(myFormPayment, auth.data._id , auth.idToken))

  //       }
  //     }

  //     useEffect(() => {
  //         if (isUpdated) {
  //             setMessage('Vendor has been updated successfully !!!')
  //             setMessageType('success')
  //             dispatch({ type: UPDATE_VENDOR_RESET });
  //             closeCreateCat()
  //         }
  //         if(isPayment){
  //           setMessage('Payment Detail has been updated successfully !!!')
  //           setMessageType('success')
  //           dispatch({ type: UPDATE_VENDOR_PAYMENT_RESET });
  //           closeCreateCat()
  //         }
  //         dispatch(getVendor(auth.data._id,auth.idToken));
  //         setIsRefresh(false)
  //    }, [dispatch, auth, isRefresh, isUpdated, isPayment])

  //    const cateRows = [];
  //     if(categories){
  //         categories.forEach((item) => {
  //             cateRows.push({
  //                 value : item._id,
  //                 label : item.name_en,
  //                 color : "#00B8D9",
  //                 isFixed: true
  //             });
  //         });
  //     }
  //     const countryCodeList = [
  //         { value: '+965', label: "+965" }
  //      ];

  return (
    <Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <PageTitle activeMenu="Profile" motherMenu="App" />

      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              <div className="photo-content">
                {/* <div className="">
                  <img
                    src={vendor.users ? process.env.REACT_APP_IMAGE_BASE_URL+vendor.users.avatar.url : profile}
                    className="img-fluid img-bg"
                    alt="profile"
                  />
                </div> */}
              </div>
              <div className="profile-info">
                <div className="profile-photo">
                  <img
                    src={
                      vendor.users
                        ? process.env.REACT_APP_IMAGE_BASE_URL +
                          vendor.users.avatar.url
                        : profile
                    }
                    className="img-fluid rounded-circle"
                    alt="profile"
                  />
                </div>
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0">
                      {vendor.users ? vendor.users.name : ""}
                    </h4>
                    <p>Name</p>
                  </div>
                  <div className="profile-email px-2 pt-2">
                    <h4 className="text-muted mb-0">
                      {vendor.users ? vendor.users.email : ""}
                    </h4>
                    <p>Email</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {/* <div className="col-xl-2"></div> */}
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
                  <ul className="nav nav-tabs">
                    <li
                      className="nav-item"
                      onClick={() => setActiveToggle("aboutMe")}
                    >
                      <Link
                        to="#about-me"
                        data-toggle="tab"
                        className={`nav-link ${
                          activeToggle === "aboutMe" ? "active show" : ""
                        }`}
                      >
                        About Me
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      id="about-me"
                      className={`tab-pane fade ${
                        activeToggle === "aboutMe" ? "active show" : ""
                      }`}
                    >
                      <div className="profile-about-me">
                        <div className="card-header">
                          <h4 className="card-title">Vendors</h4>
                          <span style={{ display: "flex", marginLeft: "5px" }}>
                            <Link
                              className="btn btn-sm btn-info mr-1"
                              to={"/profile/update"}
                            >
                              Update Profile
                            </Link>
                          </span>
                        </div>
                      </div>
                      <div className="profile-personal-info">
                        <h4 className="text-primary mb-4">
                          Personal Information
                        </h4>
                        <div className="row mb-2">
                          <div className="col-3">
                            <h5 className="f-w-500">
                              {" "}
                              Name<span className="pull-right">:</span>
                            </h5>
                          </div>
                          <div className="col-9">
                            <span>{vendor.users ? vendor.users.name : ""}</span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-3">
                            <h5 className="f-w-500">
                              {" "}
                              Business Name<span className="pull-right">:</span>
                            </h5>
                          </div>
                          <div className="col-9">
                            <span>{vendor ? vendor.businessName_en : ""}</span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-3">
                            <h5 className="f-w-500">
                              Email<span className="pull-right">:</span>
                            </h5>
                          </div>
                          <div className="col-9">
                            <span>{vendor.users ? vendor.users.name : ""}</span>
                          </div>
                        </div>

                        <div className="row mb-2">
                          <div className="col-3">
                            <h5 className="f-w-500">
                              Address<span className="pull-right">:</span>
                            </h5>
                          </div>
                          <div className="col-9">
                            <span>
                              {vendor.address ? vendor.address.address : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};
export default connect(mapStateToProps)(AppProfile);
