import {
    ADD_VENDOR_REQUEST,
    ADD_VENDOR_SUCCESS,
    ADD_VENDOR_FAIL,
    ADD_VENDOR_RESET,

    CLEAR_ERRORS,

    UPDATE_VENDOR_REQUEST,
    UPDATE_VENDOR_SUCCESS,
    UPDATE_VENDOR_FAIL,
    UPDATE_VENDOR_RESET,


    GET_VENDOR_REQUEST,
    GET_VENDOR_SUCCESS,
    GET_VENDOR_FAIL,
    GET_VENDOR_RESET,

    GET_VENDOR_REPORT_REQUEST,
    GET_VENDOR_REPORT_SUCCESS,
    GET_VENDOR_REPORT_FAIL,
    GET_VENDOR_REPORT_RESET,

    VENDOR_LIST_REQUEST,
    VENDOR_LIST_SUCCESS,
    VENDOR_LIST_FAIL,


 } from "../Constants/index";


export const VendorListReducer = (state = {vendors:[]}, action) => {
    switch(action.type){
        case VENDOR_LIST_REQUEST:
            return{
                loading:true,
                vendors:[]
            }
        case VENDOR_LIST_SUCCESS:
            return{
                loading:false,
                vendors:action.payload.data
            }
        case VENDOR_LIST_FAIL:
            return{
                loading:false,
                error:action.payload

            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error:null
            }
        default:
            return state
    }
}

export const AddVendorReducer = (state = {vendor : {}},action) => {
    switch(action.type){
        case ADD_VENDOR_REQUEST:
            return{
                ...state,
                loading:true
            }
        case ADD_VENDOR_SUCCESS:
            return{
                loading:false,
                success:true,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        case ADD_VENDOR_FAIL:
            console.log("dsdsds",action.payload.data)
            return{
                ...state,
                loading: false,
                error: action.payload.data.message,
            }
        case ADD_VENDOR_RESET:
            return{
                ...state,
                success:false
            }
        default:
            return state
    }
}

export const updateVendorReducer = (state = {},action) => {
    switch(action.type){
        case UPDATE_VENDOR_REQUEST:
            return{
                ...state,
                loading:true
            }
        case UPDATE_VENDOR_SUCCESS:
            return{
                ...state,
                loading:false,
                isUpdated:true
            }
        case UPDATE_VENDOR_FAIL:
            return{
                ...state,
                loading:false,
                error: action.payload.data.message,
            }
        case UPDATE_VENDOR_RESET:
            return{
                ...state,
                isUpdated:false
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state
    }
}

export const VendorDetailReducer = (state = {vendor : {}}, action) => {
    switch(action.type){
        case GET_VENDOR_REQUEST:
            return{
                ...state,
                loading:true,
            }
        case GET_VENDOR_SUCCESS:
            return{
                loading:false,
                success:action.payload.success,
                vendor:action.payload.data
            }
        case GET_VENDOR_FAIL:
            return{
                ...state,
                loading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        case GET_VENDOR_RESET:
            return {
                ...state,
                success: false,
            };
        default:
            return state;
        
    }

}

export const VendorReportReducer = (state = {reports:[]}, action) => {
    switch(action.type){
        case GET_VENDOR_REPORT_REQUEST:
            return{
                loading:true,
                reports:[]
            }
        case GET_VENDOR_REPORT_SUCCESS:
            return{
                loading:false,
                reports:action.payload.data
            }
        case GET_VENDOR_REPORT_FAIL:
            return{
                loading:false,
                error:action.payload

            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error:null
            }
        case GET_VENDOR_REPORT_RESET:
            return {
                reports:[],
                success: false,
            };
        default:
            return state
    }
}



