
export const authValidation = (values) => {
    let errors = {};
    if(!values.email){
        errors.email = "email is required";
    }

    if(!values.password){
        errors.password = "password is required";
    }

    return errors;
}
