import {
    CLEAR_ERRORS,
    GET_RESELLER_REQUEST,
    GET_RESELLER_SUCCESS,
    GET_RESELLER_FAIL,
    DELETE_RESELLER_REQUEST,
    DELETE_RESELLER_SUCCESS,
    DELETE_RESELLER_FAIL,
    ADD_RESELLER_REQUEST,
    ADD_RESELLER_SUCCESS,
    ADD_RESELLER_FAIL,
    ADD_RESELLER_RESET,
    UPDATE_RESELLER_REQUEST,
    UPDATE_RESELLER_SUCCESS,
    UPDATE_RESELLER_FAIL,
    UPDATE_RESELLER_RESET,
    GET_RESELLER_DETAIL_REQUEST,
    GET_RESELLER_DETAIL_SUCCESS,
    GET_RESELLER_DETAIL_FAIL,
    GET_RESELLER_DETAIL_RESET,

 } from "../Constants/index";
import axios from 'axios';
import { BASE_URL } from "../Utils";

export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};

export const addReseller = (formData, token) => async(dispatch) => {
    try{

         dispatch({ type:ADD_RESELLER_REQUEST });
         let link = `${BASE_URL}admin/reseller`;
         const config = { headers: {
             "Content-Type": "application/json",
             "Authorization":`Bearer ${token}`
         }};
         const {data} = await axios.post(link,JSON.stringify(formData), config);
         dispatch({type:ADD_RESELLER_SUCCESS,payload:data})
    }catch(err){
        console.log("error", err.response)
        dispatch({type:ADD_RESELLER_FAIL,payload: err.response});
    }
}

export const updateReseller = (formData, token) => async (dispatch) => {
    try{
        console.log(formData)
        dispatch({type:UPDATE_RESELLER_REQUEST});
        let link = `${BASE_URL}admin/reseller`;
         const config = { headers: {
            "Content-Type": "application/json",
            "Authorization":`Bearer ${token}`
         }};
        const {data} = await axios.put(link,JSON.stringify(formData),config);
        setTimeout(() => {
            dispatch({type:UPDATE_RESELLER_SUCCESS,payload:data})
        },200);
    }catch(err){
        dispatch({type:UPDATE_RESELLER_FAIL,payload: err.response,});
    }
}

export const ResellerList = (token,queryString) => async(dispatch) => {
    try{
        dispatch({
            type:GET_RESELLER_REQUEST
        });
        const config = { headers: {
            "Content-Type": "application/json",
            "Authorization":`Bearer ${token}`}
        };
        const { data } = await axios.get(`${BASE_URL}admin/reseller/list?limit=1000${queryString}`,config);
        dispatch({type:GET_RESELLER_SUCCESS, payload:data})
    }catch(err){
        dispatch({
            type: GET_RESELLER_FAIL,
            payload: err.response,
          });
    }
}

export const ResellerDetails = (token,id) => async(dispatch) => {
    try{
        dispatch({
            type:GET_RESELLER_DETAIL_REQUEST
        });
        const config = { headers: {
            "Content-Type": "application/json",
            "Authorization":`Bearer ${token}`}
        };
        const { data } = await axios.get(`${BASE_URL}admin/reseller?resellerId=${id}`,config);
        dispatch({type:GET_RESELLER_DETAIL_SUCCESS, payload:data})
    }catch(err){
        dispatch({
            type: GET_RESELLER_DETAIL_FAIL,
            payload: err.response,
          });
    }
}

export const deleteReseller = (token,id) => async(dispatch) => {
    try{
        dispatch({
            type:DELETE_RESELLER_REQUEST
        });
        const config = { headers: {
            "Content-Type": "application/json",
            "Authorization":`Bearer ${token}`}
        };
        const { data } = await axios.delete(`${BASE_URL}admin/reseller/?resellerId=${id}`,config);
        dispatch({type:DELETE_RESELLER_SUCCESS, payload:data})
    }catch(err){
        dispatch({
            type: DELETE_RESELLER_FAIL,
            payload: err.response,
          });
    }
}

// export const VendorReport = (token,type,queryString) => async(dispatch) => {
//     try{
//         dispatch({
//             type:GET_VENDOR_REPORT_REQUEST
//         });
//         const config = { headers: {
//             "Content-Type": "application/json",
//             "Authorization":`Bearer ${token}`}
//         };
//         const { data } = await axios.get(`${BASE_URL}admin/vendor/${type}?${queryString}`,config);
//         dispatch({type:GET_VENDOR_REPORT_SUCCESS, payload:data})
//     }catch(err){
//         dispatch({
//             type: GET_VENDOR_REPORT_FAIL,
//             payload: err.response,
//           });
//     }
// }

