import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAIL, LOAD_USER_REQUEST, LOAD_USER_SUCCESS, LOAD_USER_FAIL } from "../Constants/AuthConstants";
import { LOGIN_CONFIRMED_ACTION } from './AuthActions';
import { logout } from '../actions/AuthActions'
import axios from 'axios';
import { BASE_URL } from "../Utils";
import {
    // formatError,
    // runLogoutTimer,
    saveTokenInLocalStorage,
    //signUp,
} from '../../services/AuthService';

export const login = (email, password) => async(dispatch) => {
    try{
        dispatch({type : LOGIN_REQUEST});
        const config = {headers : {"Content-Type":"application/json"}} 
        const {data} = await axios.post(BASE_URL+'admin/auth/login',{
            email,password
        },config);
        dispatch({type : LOGIN_SUCCESS, payload:data});
        saveTokenInLocalStorage({...data, ['expireDate'] : 36000});
        dispatch({type:LOGIN_CONFIRMED_ACTION,payload:data});
        console.log("data",data)
        localStorage.setItem("token",JSON.stringify(data.token.token));
    }catch(error){
        dispatch({ type: LOGIN_FAIL, payload: error.response });   
    }
}

export const loadUser = () => async(dispatch, history) => {
    try{
        const tokenString = localStorage.getItem("users");
        if (!tokenString) {
            dispatch(logout(history));
            return;
        }
        const {token} = JSON.parse(tokenString);
       
        dispatch({ type: LOAD_USER_REQUEST });
        const config = { headers: { "Content-Type": "application/json","Authorization":`${token}`} };
        const { data } = await axios.get(`${BASE_URL}api/v1/profile`,config);
        //console.log("loadUser",data);
        dispatch({ type: LOAD_USER_SUCCESS, payload: data.user });
    }catch(error){
        dispatch({ type: LOAD_USER_FAIL, payload: error });
    }
}

export async function mfaEnablePost(mfa) {
    const tokenString = localStorage.getItem("user");
    // if (!tokenString) {
    //     dispatch(logout(history));
    //     return;
    // }
    const {idToken} = JSON.parse(tokenString);
    console.log(idToken)
    const config = { headers: { "Content-Type": "application/json","Authorization":`Bearer ${idToken}`} };
    const response = await axios.put(`${BASE_URL}admin/auth/mfa`,mfa,config);
    return response.data.data;
  }