export const humanReadableFormattedDateString = (date) => {
    date = new Date(date);
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let day = dateDigits(date.getDate());
    let month = months[date.getMonth()];
    let year = date.getFullYear();
    return day + ' ' + month + ', ' + year;
}

export const dateDigits = (v, digits = 2) => {
    let str = "";
    for (let i = digits; i > 1; i--) {
        str += "0";
    }

    return (str + v).slice(-digits);
};