import React, {useEffect, useState} from "react";
import LinearProgress from '@mui/material/LinearProgress';
import { Button, Modal } from "react-bootstrap";
import useForm from '../../../services/formHook';
import {addVendorValidation} from '../../../services/validation';
import { addVendor, updateVendor } from "../../../store/actions/VendorAction";
import { connect, useDispatch, useSelector } from 'react-redux';
import { ADD_VENDOR_RESET, UPDATE_VENDOR_RESET } from "../../../store/Constants";
import Select from "react-select";
// import {toast } from 'react-toastify'

// import TextField from '@mui/material/TextField';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const AddVendor = ({open, setOpen,auth, setAlert, selectedItem, setSelectedItem, type, setType, setIsRefresh, options}) => {

    const dispatch = useDispatch();
    const [err, setErr] = useState(null);
    const {
        loading, 
        error, 
        success
    } = useSelector((state) => state.AddVendorReducer);
    const [reseller, setReseller] = useState({});
    const {
        loading:updateLoading, 
        error:updateError, 
        isUpdated
    } = useSelector((state) => state.updateVendor)
    const initialValues = {
        name:"",
        password:"",
        email:"",
        screens:0,
        //duration:0,
        startDate:'',
        endDate:'',
        type
    }
    const {values, errors, handleChange, handleSubmit, handleUpdate} = useForm(initialValues,addVendorForm,addVendorValidation)
    function addVendorForm(e){
        const {name, email, password, screens, 
            //duration,
            startDate, endDate} = values
        let data = {
            name, email, password, screens, 
            //duration, 
            startDate, endDate, resellerId:reseller.value
        }
        if(type && type == 'edit'){
            let data = {
                name, screens, startDate:startDate+"T00:00:00.000Z", endDate:endDate+"T00:00:00.000Z",vendorId:selectedItem._id
            }
            dispatch(updateVendor(data,auth.idToken))
        }else{
            console.log(data);
            dispatch(addVendor(data,auth.idToken))
        }
        setIsRefresh(true);
    }

    useEffect(() => {
        console.log("check",selectedItem, type)
        if(error){
            setErr(error)
        }
        if(updateError){
            setErr(updateError)
        }
        if(success){
            setOpen(false);
            setErr(null);
            dispatch({type:ADD_VENDOR_RESET});
            setAlert("success", 'Vendor has been created successfully !!!')
            handleUpdate({
                name:"",
                email:"",
                screens:0,
                duration:0,
                startDate:'',
                endDate:''
            })
        }
        if(isUpdated){
            setOpen(false);
            setErr(null);
            dispatch({type:UPDATE_VENDOR_RESET});
            setAlert("success", 'Vendor has been updated successfully !!!')
        }
        if(selectedItem && type){
            console.log("sDate",selectedItem && selectedItem.subscription ? selectedItem.subscription.startDate.split("T")[0] : '');
            handleUpdate({
                name:selectedItem.name,
                email:selectedItem.email,
                screens:selectedItem.totalScreens,
                duration:selectedItem.duration,
                startDate:selectedItem && selectedItem.subscription ? selectedItem.subscription.startDate.split("T")[0] : '',
                endDate:selectedItem && selectedItem.subscription ? selectedItem.subscription.endDate.split("T")[0] : '',
                type
            })
        }else{
            handleUpdate({
                name:"",
                password:"",
                email:"",
                screens:0,
                duration:0,
                startDate:'',
                endDate:'',
                type:null
            })
        }
    },[dispatch, auth, error, success,selectedItem, updateError, isUpdated, type])
   return (
        <Modal className="fade" show={open} size="xl">
            {loading ? <LinearProgress /> : '' }
            <Modal.Header>
                <Modal.Title>{selectedItem ? 'Update' : 'Add'} Client</Modal.Title>
                    <Button
                        variant=""
                        className="close"
                        onClick={() => {setOpen(false)}}
                    >
                        <span>&times;</span>
                    </Button>
            </Modal.Header>
            <Modal.Body>
                
                <div className="basic-form">
                    {err && <h6 className="alert alert-danger">{err}</h6>}
                    <form 
                        onSubmit={handleSubmit}
                        autoComplete="off"
                    >
                        <div className="row">
                            <div className="col-md-6">
                                <div
                                    className={`form-group ${
                                        errors && errors.name
                                            ? "input-danger-o"
                                            : "input-success-o"
                                    }`}
                                >
                                    <label className="text-label">
                                        UserName
                                    </label>
                                    <div className="input-group-md">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            placeholder="Enter a name.."
                                            name="name"
                                            onChange={handleChange}
                                            value={values && values.name}
                                        />
                                        <div
                                            id="val-username1-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        >
                                            {errors && errors.name}
                                        </div>

                                        <div
                                            id="val-username1-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {
                                !type && !selectedItem ? <div className="col-md-6">
                                <div
                                    className={`form-group ${
                                        errors && errors.email
                                            ? "input-danger-o"
                                            : "input-success-o"
                                    }`}
                                >
                                    <label className="text-label">
                                        Email
                                    </label>
                                    <div className="input-group-md">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            placeholder="Enter a email"
                                            name="email"
                                            onChange={handleChange}
                                            value={values && values.email}
                                            autoComplete="off"
                                        />
                                        <div
                                            id="val-username1-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        >
                                            {errors && errors.email}
                                        </div>

                                        <div
                                            id="val-username1-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        />
                                    </div>
                                </div>
                            </div> : ''
                            }
                            {
                                !type && !selectedItem ? 
                                <div className="col-md-6">
                                <div
                                    className={`form-group ${
                                        errors && errors.password
                                            ? "input-danger-o"
                                            : "input-success-o"
                                    }`}
                                >
                                    <label className="text-label">
                                        Password
                                    </label>
                                    <div className="input-group-md">
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="password"
                                            placeholder="Enter a Password.."
                                            name="password"
                                            onChange={handleChange}
                                            value={values && values.password}
                                        />
                                        <div
                                            id="val-password-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        >
                                            {errors && errors.password}
                                        </div>

                                        <div
                                            id="val-password-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        />
                                    </div>
                                </div>
                            </div> : ''
                            }
                            <div className="col-md-6">
                                <div
                                    className={`form-group ${
                                        errors && errors.screens
                                            ? "input-danger-o"
                                            : "input-success-o"
                                    }`}
                                >
                                    <label className="text-label">
                                        No of Screen
                                    </label>
                                    <div className="input-group-md">
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="screens"
                                            placeholder="Enter a Screen Number.."
                                            name="screens"
                                            onChange={handleChange}
                                            value={values && values.screens}
                                            min={0}
                                        />
                                        <div
                                            id="val-screen-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        >
                                            {errors && errors.screens}
                                        </div>

                                        <div
                                            id="val-screen-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* {
                                !type && !selectedItem ? <div className="col-md-12">
                                <div
                                    className={`form-group ${
                                        errors && errors.duration
                                            ? "input-danger-o"
                                            : "input-success-o"
                                    }`}
                                >
                                    <label className="text-label">
                                        Duration (Min:1, Max:24)
                                    </label>
                                    <div className="input-group-md">
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="duration"
                                            placeholder="Enter a Duration.."
                                            name="duration"
                                            onChange={handleChange}
                                            value={values && values.duration}
                                        />
                                        <div
                                            id="val-screen-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        >
                                            {errors && errors.duration}
                                        </div>

                                        <div
                                            id="val-screen-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        />
                                    </div>
                                </div>
                            </div>: ''
                            } */}
                            <div className="col-md-6">
                                <div
                                    className={`form-group ${
                                        errors && errors.startDate
                                            ? "input-danger-o"
                                            : "input-success-o"
                                    }`}
                                >
                                    <label className="text-label">Start Date</label>
                                    <div className="input-group-md">
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="startDate"
                                            placeholder="Enter a Duration.."
                                            name="startDate"
                                            onChange={handleChange}
                                            value={values && values.startDate}
                                        />
                                        <div
                                            id="val-screen-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        >
                                            {errors && errors.startDate}
                                        </div>

                                        <div
                                            id="val-screen-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div
                                    className={`form-group ${
                                        errors && errors.endDate
                                            ? "input-danger-o"
                                            : "input-success-o"
                                    }`}
                                >
                                    <label className="text-label">End Date</label>
                                    <div className="input-group-md">
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="endDate"
                                            placeholder="Enter a Duration.."
                                            name="endDate"
                                            onChange={handleChange}
                                            value={values && values.endDate}
                                        />
                                        <div
                                            id="val-screen-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        >
                                            {errors && errors.endDate}
                                        </div>

                                        <div
                                            id="val-screen-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {
                                !type && !selectedItem ?
                                <div className="col-md-6">
                                <div
                                    className={`form-group ${
                                        errors && errors.reseller
                                            ? "input-danger-o"
                                            : "input-success-o"
                                    }`}
                                >
                                    <label className="text-label">Reseller</label>
                                    <Select
                                        value={reseller}
                                        onChange={setReseller}
                                        options={options}
                                        className="app-option"
                                    />
                                </div>
                            </div>
                                 :<></>
                            }
                            
                        </div>
                        
                        <button
                            type="submit"
                            className="btn text-white"
                            style={{ backgroundColor: "#B3005E" }}
                            disabled={loading}
                        >
                            Submit
                        </button>
                        <button
                            type="button"
                            onClick={() =>{setOpen(false)}}
                            className="btn text-white"
                            style={{ backgroundColor: "#B3005E",marginLeft:'2px' }}
                        >
                        Cancel
                        </button>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
   );
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth,
    };
};
export default connect(mapStateToProps)(AddVendor);

