
export const ADD_VENDOR_REQUEST = 'ADD_VENDOR_REQUEST';
export const ADD_VENDOR_SUCCESS = 'ADD_VENDOR_SUCCESS';
export const ADD_VENDOR_FAIL = 'ADD_VENDOR_FAIL';
export const ADD_VENDOR_RESET = 'ADD_VENDOR_RESET';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const GET_VENDOR_REQUEST = 'GET_VENDOR_REQUEST';
export const GET_VENDOR_SUCCESS = 'GET_VENDOR_SUCCESS';
export const GET_VENDOR_FAIL = 'GET_VENDOR_FAIL';
export const GET_VENDOR_RESET = 'GET_VENDOR_RESET';

export const VENDOR_REQUEST = 'VENDOR_REQUEST';
export const VENDOR_SUCCESS = 'VENDOR_SUCCESS';
export const VENDOR_FAIL = 'VENDOR_FAIL';

export const UPDATE_VENDOR_REQUEST = 'UPDATE_VENDOR_REQUEST';
export const UPDATE_VENDOR_SUCCESS = 'UPDATE_VENDOR_SUCCESS';
export const UPDATE_VENDOR_FAIL = 'UPDATE_VENDOR_FAIL';
export const UPDATE_VENDOR_RESET = 'UPDATE_VENDOR_RESET';

export const VENDOR_LIST_REQUEST = 'VENDOR_LIST_REQUEST';
export const VENDOR_LIST_SUCCESS = 'VENDOR_LIST_SUCCESS';
export const VENDOR_LIST_FAIL = 'VENDOR_LIST_FAIL';

export const GET_VENDOR_REPORT_REQUEST = 'GET_VENDOR_REPORT_REQUEST';
export const GET_VENDOR_REPORT_SUCCESS = 'GET_VENDOR_REPORT_SUCCESS';
export const GET_VENDOR_REPORT_FAIL = 'GET_VENDOR_REPORT_FAIL';
export const GET_VENDOR_REPORT_RESET = 'GET_VENDOR_REPORT_RESET';

export const GET_RESELLER_REQUEST = 'GET_RESELLER_REQUEST';
export const GET_RESELLER_SUCCESS = 'GET_RESELLER_SUCCESS';
export const GET_RESELLER_FAIL = 'GET_RESELLER_FAIL';
export const DELETE_RESELLER_REQUEST = 'DELETE_RESELLER_REQUEST';
export const DELETE_RESELLER_SUCCESS = 'DELETE_RESELLER_SUCCESS';
export const DELETE_RESELLER_FAIL = 'DELETE_RESELLER_FAIL';
export const GET_RESELLER_RESET = 'GET_RESELLER_RESET';

export const GET_RESELLER_DETAIL_REQUEST = 'GET_RESELLER_DETAIL_REQUEST';
export const GET_RESELLER_DETAIL_SUCCESS = 'GET_RESELLER_DETAIL_SUCCESS';
export const GET_RESELLER_DETAIL_FAIL = 'GET_RESELLER_DETAIL_FAIL';
export const GET_RESELLER_DETAIL_RESET = 'GET_RESELLER_DETAIL_RESET';

export const UPDATE_RESELLER_REQUEST = 'UPDATE_RESELLER_REQUEST';
export const UPDATE_RESELLER_SUCCESS = 'UPDATE_RESELLER_SUCCESS';
export const UPDATE_RESELLER_FAIL = 'UPDATE_RESELLER_FAIL';
export const UPDATE_RESELLER_RESET = 'UPDATE_RESELLER_RESET';

export const ADD_RESELLER_REQUEST = 'ADD_RESELLER_REQUEST';
export const ADD_RESELLER_SUCCESS = 'ADD_RESELLER_SUCCESS';
export const ADD_RESELLER_FAIL = 'ADD_RESELLER_FAIL';
export const ADD_RESELLER_RESET = 'ADD_RESELLER_RESET';


