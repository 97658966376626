import React, { Fragment, useEffect, useState } from "react";
import Rating from '@mui/material/Rating';
import { Dropdown, Badge, Button, Card } from "react-bootstrap";
import { DataGrid, 
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector, } from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import { connect} from 'react-redux';
import LinearProgress from '@mui/material/LinearProgress';
import CustomNoRowsOverlay from '../../components/NoRows';
import QuickSearchToolbar from '../../components/QuickSearchToolBar';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import 'react-toastify/dist/ReactToastify.css'



  function CustomToolbar() {
      return (
        <GridToolbarContainer>
          <QuickSearchToolbar />
          <GridToolbarExport />
        </GridToolbarContainer>
      );
  }

  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  
    return (
      <Pagination
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }

const Uptime = ({data, loading}) => {
    const svg1 = (
      <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect x="0" y="0" width="24" height="24"></rect>
          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
        </g>
      </svg>
    );


    const getUptimeData = (data) => {
      const sumOfTime = data?.uptimeReport?.reduce(
          (total, obj) => total + obj?.time,
          0
        );

        // Convert the sum to hours
        const sumInHours = sumOfTime;
        const hours = Math.floor(sumOfTime / 60);
        const minutes = sumOfTime % 60;

        const formattedSum = `${hours} hr ${
          minutes < 10 ? "0" : ""
        }${Math.floor(minutes)} min`;

        const average = data?.uptimeReport?.length >0 ? sumInHours / data?.uptimeReport?.length:0;
        const aveHours = average? Math.floor(average / 60):0;
        const aveMinutes = average? average % 60:0;

        const aveFormattedSum = `${aveHours} hr ${
          aveMinutes < 10 ? "0" : ""
        }${Math.floor(aveMinutes)} min`;

        return [formattedSum, aveFormattedSum];
  }


   const columns = [
    { field: 'name', headerName: 'Screen',flex:1, disableSelectionOnClick:true},
    { field: 'uptime', headerName: 'Total Uptime',flex:1, disableSelectionOnClick:true},
    { field: 'avg_time', headerName: 'Total Avg time',flex:1, disableSelectionOnClick:true},
    
  ];
    const rows = [];
    if(data){
       data.forEach((item) => {
        // const getUpData = getUptimeData(item);
          rows.push({
                id: item._id,
                name:item.name,
                uptime:getUptimeData(item)[0],
                avg_time:getUptimeData(item)[1],
            });
        });     
    }
    
   return (
    <DataGrid
        components={{
            NoRowsOverlay: CustomNoRowsOverlay,
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
            Pagination: CustomPagination,
        }}
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[10]}
        pagination
        disableSelectionOnClick
        autoHeight={true}
        loading={loading}
    />
   );
};

const mapStateToProps = (state) => {
   return {
       auth: state.auth.auth,
   };
 };
 export default connect(mapStateToProps)(Uptime);
