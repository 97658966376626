import React, {useState} from "react";
import { connect, useDispatch } from "react-redux";
//import { Link} from 'react-router-dom'
import {
  loadingToggleAction,
  loginAction,
} from "../../store/actions/AuthActions";
import Select from "react-select";
import useForm from "../../store/Middleware/form";
import { authValidation } from "../../store/Validation/index";
import logo from "./../../img/logoipsum-logo-13 1 (1).png";

//
// import logo from '../../images/logo.png'
// import logotext from '../../images/logo-text-white.png'

function Login(props) {
  const dispatch = useDispatch();

  const initialValues = {
    email: "",
    password: "",
    loginType: ""
  };

  const options = [
    { value: "admin", label: "Admin" },
    { value: "reseller", label: "Reseller" }
  ];
  const [role, setRole] = useState({ value: "admin", label: "Admin" });
  const { values, errors, handleChange, handleSubmit } = useForm(
    initialValues,
    onLogin,
    authValidation
  );

  function onLogin(e) {
    console.log(values);
    //dispatch(login(values.email, values.password));
    dispatch(loadingToggleAction(true));
    dispatch(loginAction(values.email, values.password, role.value, props.history));
    // let error = false;
    // const errorObj = { ...errorsObj };
    // if (email === '') {
    //     errorObj.email = 'Email is Required';
    //     error = true;
    // }
    // if (password === '') {
    //     errorObj.password = 'Password is Required';
    //     error = true;
    // }
    // setErrors(errorObj);
    // if (error) {
    // 	return ;
    // }
    // dispatch(loadingToggleAction(true));
    // dispatch(loginAction(email, password, props.history));
  }
console.log(process.env.REACT_APP_API_BASE_URL, "kkkkkkk")
console.log(process.env.REACT_APP_BUCKET_NAME, "pppppp")
  return (
    <div className="login-form-bx">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-md-7 box-skew d-flex">
            <div className="authincation-content">
              <div className="mb-4">
                <h2 className="mb-1 font-w600 text-center">TRENDY</h2>
                <p className="">Sign in by entering information below</p>
              </div>
              {props.errorMessage && (
                <h6 className="alert alert-danger">{props.errorMessage}</h6>
              )}
              {props.successMessage && (
                <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                  {props.successMessage}
                </div>
              )}
              <form onSubmit={handleSubmit} autoComplete="off">
              <div className="form-group">
            <label className="mt-3">Select Role</label>
              <Select
              value={role}
              onChange={setRole}
              placeholder="Select Role"
              options={options}
              className="app-option"
            />
            </div>
                <div className="form-group">
                  <label className="mb-2 ">
                    <strong className="">Email</strong>
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                    onChange={handleChange}
                    autoComplete="off"
                  />
                  {errors.email && (
                    <div className="text-danger fs-12">{errors.email}</div>
                  )}
                </div>
                <div className="form-group">
                  <label className="mb-2 ">
                    <strong className="">Password</strong>
                  </label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    className="form-control"
                    onChange={handleChange}
                  />
                  {errors.password && (
                    <div className="text-danger fs-12">{errors.password}</div>
                  )}
                </div>
                <div className="form-row d-flex justify-content-between mt-4 mb-2">
                  <div className="form-group">
                    <div className="custom-control custom-checkbox ml-1 ">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="basic_checkbox_1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="basic_checkbox_1"
                      >
                        Remember my preference
                      </label>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn  btn-block text-white"
                    style={{ backgroundColor: "#B3005E" }}
                  >
                    Sign In
                  </button>
                </div>
              </form>
              {/* <div className="new-account mt-2">
								<p className="mb-0">Don't have an account?{" "}
									<Link className="text-black" to="./page-register">Sign up</Link>
								</p>
							</div> */}
            </div>
          </div>
          <div className="col-lg-6 col-md-5 d-flex box-skew1">
            <div className="inner-content align-self-center">
              {/* <Link to="/dashboard" className="login-logo">
								<img src={logo} alt="" className="logo-icon mr-2"/>
								<img src={logotext} alt="" className="logo-text ml-1"/>
							</Link> */}
              <div className="d-flex align-items-center">
                <img src={logo} alt="icon" className="mr-3" />
                <h1 className="m-b10 text-white mb-0">
                  <strong>TRENDY</strong>
                </h1>
              </div>
              {/* <p className="m-b40 text-white">User Experience & Interface Design Strategy SaaS Solutions</p> */}
              {/* <ul className="social-icons mt-4">
								<li><Link to={"#"}><i className="fa fa-facebook"></i></Link></li>
								<li><Link to={"#"}><i className="fa fa-twitter"></i></Link></li>
								<li><Link to={"#"}><i className="fa fa-linkedin"></i></Link></li>
							</ul> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
//export default Login;
const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);
