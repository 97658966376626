import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
} from '../../services/AuthService';
import swal from "sweetalert";
import axios from 'axios';
import { BASE_URL } from "../Utils";
import { mfaEnablePost } from '../actions/Auth'

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';



export const getProfile = (token) => async (dispatch) => {
    try{
        dispatch({type:GET_PROFILE_REQUEST});
        let link = `${BASE_URL}api/v1/profile`;
         const config = { headers: {
             "Content-Type": "multipart/form-data",
             "Authorization":`${token}`
         }};
        const {data} = await axios.get(link,config);
        dispatch({type:GET_PROFILE_SUCCESS,payload:data})
    }catch(err){
        dispatch({type:GET_PROFILE_FAIL,payload: err.response,});
    }
}

export function signupAction(email, password, history) {
    return (dispatch) => {
        signUp(email, password)
        .then((response) => {
            saveTokenInLocalStorage(response.data);
            runLogoutTimer(
                dispatch,
                response.data.expiresIn * 1000,
                history,
            );
            dispatch(confirmedSignupAction(response.data));
            history.push('/');
        })
        .catch((error) => {
            const errorMessage = formatError(error.response.data);
            dispatch(signupFailedAction(errorMessage));
        });
    };
}

export function logout(history) {
    localStorage.removeItem('user');
    history.push('/login');
    return {
        type: LOGOUT_ACTION,
    };
}

export function loginAction(email, password, role, history) {
    console.log(email, password, role, history)
    // const mfa = 'R3FF42XS2JQSOIBKPSHU3JI';
    // const mfaEnabled = true;
    return (dispatch) => {
        login(email, password, role)
            .then((response) => {
                console.log("test",response)
                if(!response.data.data.admin.mfa){
                    response.data.data.admin.mfa = null
                }
                if(!response.data.data.admin.mfaEnabled){
                    response.data.data.admin.mfaEnabled = false
                    response.data.data.admin.isVerified = true;
                }
                // response.data.data.admin.mfa = mfa;
                // response.data.data.admin.mfaEnabled = mfaEnabled;
                if(response.data.data.admin.mfaEnabled){
                    response.data.data.admin.isVerified = false;
                }
                const token = response.data.data;
                saveTokenInLocalStorage({
                    ...response.data.data.admin,
                    ['expiresIn']:36000,['idToken']:response.data.data.token.token
                });
                dispatch(loginConfirmedAction(response.data));
				history.push('/clients');
				window.location.reload();
                
				//history.pushState('/index');
                
            })
            .catch((error) => { 
                console.log("error",error)
                //const errorMessage = formatError(error.response.data.message);
                const errorMessage = error.response.data.message;
                console.log("errorMessage",errorMessage)
                swal('OOPS', errorMessage, "error",{ button: "Try Again!",});
                dispatch(loginFailedAction(errorMessage));
            });
    };
}

export function verification2FaAuth(mfa, history) {
    return (dispatch) => {
        mfaEnablePost(mfa)
            .then((response) => {
                console.log("MFA", response);
                const tokenDetailsString = localStorage.getItem('user');
                let token = JSON.parse(tokenDetailsString);
                token.mfa = mfa.mfa;
                token.mfaEnabled = mfa.mfaEnabled;
                saveTokenInLocalStorage(token);
            })
            .catch((error) => {
                const errorMessage = error.response.data.message;
                console.log("MFA errorMessage",errorMessage)
                dispatch(loginFailedAction(errorMessage));
            });
    };
}


export function verify2fa(mfaToken, history) {
    return (dispatch) => {
        const tokenDetailsString = localStorage.getItem('user');
        let token = JSON.parse(tokenDetailsString);
        console.log("token",token, tokenDetailsString)
        token.isVerified=true;
        token.mfa = mfaToken;
        saveTokenInLocalStorage(token);
        dispatch(loginConfirmedAction(token));
        history.push('/clients');
        window.location.reload();
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
