import React, {useState, useEffect} from "react";
import { connect, 
} from 'react-redux';
import { Link } from "react-router-dom";

/// Image
import profile from "../../../images/profile/pic1.jpg";
import { Dropdown } from "react-bootstrap";
import LogoutPage from './Logout';
import ChangePassword from "./ChangePassword";
import { Button } from "react-bootstrap";


const Header = ({auth}) => {
  const [open, setOpen] = useState(false)
  const setClose = () => {
    setOpen(false);

  }
  const setOpenPassword = () => {
    setOpen(true)
  }
  return (
    <div className="header" style={{ zIndex: "11" }}>
      <ChangePassword open={open} setClose={setClose} />
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              
            </div>
            <ul className="navbar-nav header-right">
              
              <Dropdown as="li" className="nav-item dropdown header-profile">
                <Dropdown.Toggle
                  variant=""
                  as="a"
                  className="i-false c-pointer nav-link"
                  to=""
                  role="button"
                  data-toggle="dropdown"
                >
                  <div className="header-info">
                    <span>
                      Hello, <strong>{auth ? auth.name : ""}</strong>
                    </span>
                  </div>
                  <img src={profile} width={20} alt="" />
                </Dropdown.Toggle>
                <Dropdown.Menu
                  align="right"
                  className="dropdown-menu dropdown-menu-right"
                >
                  {/* <Link to="/profile" className="dropdown-item ai-icon">
                    <svg
                      id="icon-user1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-primary"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                      <circle cx={12} cy={7} r={4} />
                    </svg>
                    <span className="ml-2">Profile </span>
                  </Link> */}
                  <Button onClick={setOpenPassword} className="dropdown-item ai-icon">
                    <svg
                      id="icon-user1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-primary"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                      <circle cx={12} cy={7} r={4} />
                    </svg>
                    <span className="ml-2">Change Password </span>
                  </Button>
                  <LogoutPage />
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

//export default Header;
const mapStateToProps = (state) => {
  return {
      // errorMessage: state.auth.errorMessage,
      // successMessage: state.auth.successMessage,
      auth: state.auth.auth,
  };
};
export default connect(mapStateToProps)(Header);
