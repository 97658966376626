export const vendorValidation = (values) => {
    let errors = {};
    if(!values.name){
        errors.name = 'Name is required';
    }
    
    return errors;
    
}
const formatDate = () => {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-')+" 00:00:00";
}
export const addVendorValidation = (values) => {
    console.log("erros", values)
    let errors = {};
    if(!values.name){
        errors.name = 'Name is required';
    }
    if(!values.email && !values.type){
        errors.email = 'Email is required';
    }
    if(!values.password  && !values.type){
        errors.password = 'Password is required';
    }
    if(!values.screens){
        errors.screens = 'Screen Number is required';
    }else if(values.screens && values.screens <= 0){
        errors.screen = 'Screen Number should be grater than 0';
    }

    // if(!values.duration  && !values.type){
    //     errors.duration = 'Duration is required';
    // }else if(values.duration  && !values.type && values.duration <= 0 || (values.duration && values.duration > 25)){
    //     errors.duration = 'Duration should be grater than 0 and less than 24';
    // }
    if(values.startDate == ''){
        errors.startDate = 'Start Date is required'
    }
    if(values.startDate != ''){
        let sd1 = new Date(formatDate()).getTime();
        let date1 = new Date(values.startDate).getTime();
        console.log(sd1, date1, formatDate())
        if(date1 < sd1){
            errors.startDate = 'Start Date must be a current or future date'
        }
        
    }
    if(values.endDate == ''){
        errors.endDate = 'End Date is required'
    }
    if(values.startDate != '' && values.endDate != ''){
        let date1 = new Date(values.startDate).getTime();
        let date2 = new Date(values.endDate).getTime();
        if(date2 < date1){
            errors.endDate = 'End Date must be greater than startDate';
        }
    }
    return errors;
}

export const addResellerValidation = (values) => {
    let errors = {};
    if(!values.name){
        errors.name = 'Name is required';
    }
    if(!values.email){
        errors.email = 'Email is required';
    }
    if(!values.password){
        errors.password = 'Password is required';
    }
    // if(!values.comission){
    //     errors.comission = 'Commission is required';
    // }else if(values.comission && values.comission <= 0){
    //     errors.comission = 'Commission should be grater than 0';
    // }
    return errors;
}

export const changePasswordValidation = (values) => {
    let errors = {};
    if(!values.oldPassword){
        errors.oldPassword = "Old Password is required";
    }

    if(!values.newPassword){
        errors.newPassword = "Password is Required";
    }
    // if(values.newPassword === values.confirmPassword){
    //     errors.confirmPassword = "Password and Confirm Password should be same";
    // }
    


    return errors;

}
