import React ,{useEffect, useState} from "react";
import LinearProgress from '@mui/material/LinearProgress';
import { Button, Modal } from "react-bootstrap";
import { withRouter } from 'react-router-dom';
import useForm from '../../../services/formHook';
import {changePasswordValidation} from '../../../services/validation';
import 'react-toastify/dist/ReactToastify.css'
import { connect, useDispatch, useSelector } from 'react-redux';
import { CHANGEPASSWORD_RESET } from '../../../store/Constants/UserConstants'
import { changePassword } from "../../../store/actions/UserAction";
//import { logout } from '../../../store/actions/AuthActions';


const ChangePassword = (props) => {
    const auth = props.auth
    const [message, setMessage] = useState("");
    const dispatch = useDispatch();
    const initialValues = {
            oldPassword:'',
            newPassword:''
        }
    const { loading, isPasswordChanged, error} = useSelector((state) => state.ChangePassword);
    const {values, errors, handleChange, handleSubmit} = useForm(initialValues,changePasswordForm,changePasswordValidation)
    function changePasswordForm(){
        dispatch(changePassword({
            oldPassword:values.oldPassword,
            newPassword:values.newPassword
        },auth.idToken))
    }
    useEffect(() => {
        if(error){
            setMessage(<h5 className="alert alert-danger text-center">{error}</h5>)
        }
        if (isPasswordChanged) {
            setMessage(<h5 className="alert alert-success text-center">Password has been changed successfully</h5>)
            setTimeout(()=>{
                props.setClose()
                dispatch({ type: CHANGEPASSWORD_RESET });
            },1000)
            
            //dispatch(logout(props.history));
            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, auth, error,isPasswordChanged])

   return (
        <Modal className="fade" show={props.open}>
            {loading ? <LinearProgress /> : '' }
            <Modal.Header>
            
                <Modal.Title>Change Password</Modal.Title>
                    <Button
                        variant=""
                        className="close"
                        onClick={props.setClose}
                    >
                        <span>&times;</span>
                    </Button>
                </Modal.Header>
            <Modal.Body>
                
            <div className="basic-form">
                        {message}
                        <form 
                            onSubmit={handleSubmit}
                            autoComplete="off"
                        >
                            <div className="row">
                                <div className="col-md-12">
                                    <div
                                        className={`form-group ${
                                            errors && errors.oldPassword
                                               ? "input-danger-o"
                                               : "input-success-o"
                                      }`}
                                    >
                                        <label className="text-label">
                                            Old Password
                                        </label>
                                        <div className="input-group-md">
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="name"
                                                placeholder="Old Password"
                                                name="oldPassword"
                                                onChange={handleChange}
                                                value={values && values.oldPassword ? values.oldPassword : ''}
                                            />
                                            <div
                                                id="val-username1-error"
                                                className="invalid-feedback animated fadeInUp"
                                                style={{ display: "block" }}
                                            >
                                                {errors && errors.oldPassword}
                                            </div>

                                            <div
                                                id="val-username1-error"
                                                className="invalid-feedback animated fadeInUp"
                                                style={{ display: "block" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div
                                        className={`form-group ${
                                            errors && errors.newPassword
                                               ? "input-danger-o"
                                               : "input-success-o"
                                      }`}
                                    >
                                        <label className="text-label">
                                            New Password
                                        </label>
                                        <div className="input-group-md">
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="newPassword"
                                                placeholder="New Password"
                                                name="newPassword"
                                                onChange={handleChange}
                                                value={values && values.newPassword ? values.newPassword : ''}
                                            />
                                            <div
                                                id="val-username1-error"
                                                className="invalid-feedback animated fadeInUp"
                                                style={{ display: "block" }}
                                            >
                                                {errors && errors.newPassword}
                                            </div>

                                            <div
                                                id="val-username1-error"
                                                className="invalid-feedback animated fadeInUp"
                                                style={{ display: "block" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <button
                            type="submit"
                            className="btn mr-2 btn-primary"
                            //disabled={isSubmitting}
                            >
                            Change
                            </button>
                            <button
                            type="button"
                            onClick={props.setClose}
                            className="btn btn-light"
                            >
                            Cancel
                            </button>
                        </form>
                     </div>
            </Modal.Body>
    </Modal>
   );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth,
    };
  };
export default withRouter(connect(mapStateToProps)(ChangePassword));
