import React, { Fragment, useEffect, useState } from "react";
import Rating from "@mui/material/Rating";
import { Dropdown, Badge, Button, Card } from "react-bootstrap";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  VendorList,
  deleteClient,
  orderStatus,
} from "../../../store/actions/VendorAction";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../components/NoRows";
import QuickSearchToolbar from "../../components/QuickSearchToolBar";
import { GridToolbarContainer } from "@mui/x-data-grid";
import AddVendor from "./AddVendor.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { ADD_VENDOR_RESET } from "../../../store/Constants";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  ResellerList,
  deleteReseller,
} from "../../../store/actions/ResellerAction";
import DeleteConfirmation from "./DeleteConfirmation.jsx";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <QuickSearchToolbar />
    </GridToolbarContainer>
  );
}

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

const Order = ({ auth }) => {
  const history = useHistory();
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const [isRefresh, setIsRefresh] = useState(false);
  const [type, setType] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { loading, error, vendors } = useSelector((state) => state.vendors);
  const { resellers } = useSelector((state) => state.resellers);
  const [deleteModal, setDeleteModal] = useState(false);

  const [selectedId ,setSelectedId] = useState("")

  const setAlert = (type, msg) => {
    if (type == "error") {
      toast.error("❌ " + msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (type == "success") {
      toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  // function terminateAccount(id) {
  //   console.log(id, "kkk");
  //   deleteClient(id._id, auth.idToken)
  //     .then((res) => {console.log(res, "delete res");
  //     setIsRefresh(true);
  //     setAlert("success", 'Vendor has been Terminated successfully !')
  //   })
  //     .catch((err) => console.log(err, "delete error"));
  // }
  const terminateAccount = async () => {
    setDeleteModal(false);
    await  deleteClient(selectedId, auth.idToken)
    .then((res) => {console.log(res, "delete res");
    setIsRefresh(true);
    setAlert("success", 'Vendor has been Terminated successfully !')
  })
    .catch((err) => console.log(err, "delete error"));
   

  };
  const handleOpen = (e) => {
    e.preventDefault();
    setSelectedItem(null);
    setType(null);
    setOpen(true);
  };

  const handleReport = (e, type) => {
    e.preventDefault();
    console.log(type);
    history.push(type);
  };
  useEffect(() => {
    if (error) {
      toast.error("❌ " + error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    dispatch({ type: ADD_VENDOR_RESET });
    dispatch(VendorList(auth.idToken, ""));
    dispatch(ResellerList(auth.idToken, ""));
    setIsRefresh(false);
  }, [isRefresh]);

  const renderAction = (params) => {
    const { value } = params;
    return (
      <Dropdown className="dropdown-action">
        <Dropdown.Toggle variant="" className="i-false">
          {svg1}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={(e) => {
              handleEdit(e, value);
            }}
          >
            Renew Subscription
          </Dropdown.Item>
          <Dropdown.Item
          //  onClick={() => terminateAccount(value)}
                          onClick={() => {
                            setSelectedId(value._id);
                            setDeleteModal(true);
                          }}
          >
            Terminate Account
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };
  const renderReport = (params) => {
    const { value } = params;
    return (
      <Dropdown className="dropdown-action">
        <Dropdown.Toggle variant="" className="i-false">
          {svg1}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item>
            <Link to={`/clients/mediaReport/6436ac4945920161d6b13dab`}>
              Media Report
            </Link>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={(e) => {
              handleReport(e, `/clients/auditLogs/6436ac4945920161d6b13dab`);
            }}
          >
            Audit Logs
          </Dropdown.Item>
          <Dropdown.Item
            onClick={(e) => {
              handleReport(e, `/clients/uptimeReport/6436ac4945920161d6b13dab`);
            }}
          >
            UpTime Report
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderStatus = (params) => {
    const { value } = params;
    let data = "";
    if (value == 0) {
      data = (
        <span className="badge light badge-info">
          <i className="fa fa-circle text-info mr-1" />
          Created
        </span>
      );
    } else if (value == 1) {
      data = (
        <span className="badge light badge-warning">
          <i className="fa fa-circle text-warning mr-1" />
          In Transit
        </span>
      );
    } else if (value == 2) {
      data = (
        <span className="badge light badge-success">
          <i className="fa fa-circle text-success mr-1" />
          Delievred
        </span>
      );
    }

    return (
      <Button className="btn-sm mr-2" variant="success light">
        Active
      </Button>
    );
  };

  const changeStatus = (status, id, token) => {
    if (status == 2) {
      var mess = "Complete";
    } else if (status == 1) {
      mess = "In Transit";
    } else {
      mess = "Create";
    }
    Swal.fire({
      title: "Are you sure?",
      text: "want to " + mess + " this order",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    }).then((result) => {
      if (result.value) {
        const myForm = new FormData();
        myForm.set("orderStatus", status);
        orderStatus(id, myForm, token).then((result) => {
          if (result.success) {
            Swal.fire("Success!", result.message, "success").then((val) => {
              setIsRefresh(true);
            });
          } else {
            Swal.fire("Error!", result.message, "error");
          }
        });
      }
    });
  };

  const handleEdit = (e, item) => {
    e.preventDefault();
    setType("edit");
    setSelectedItem(item);
    console.log("edit", item);
    setOpen(true);
  };
  //End
  const renderStartDate = (params) => {
    const { value } = params;
    return params.row.startDate ? params.row.startDate.startDate.split("T")[0] : "--";
  };
  const renderEndDate = (params) => {
    const { value } = params;
    return value ? value.endDate.split("T")[0] : "--";
  };
  const columns = [
    {
      field: "id",
      headerName: "Client ID",
      flex: 1,
      disableSelectionOnClick: true,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      disableSelectionOnClick: true,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      disableSelectionOnClick: true,
    },
    {
      field: "reseller",
      headerName: "Reseller",
      flex: 1,
      disableSelectionOnClick: true,
    },
    {
      field: "screenCount",
      headerName: "Screens",
      flex: 1,
      disableSelectionOnClick: true,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      flex: 1,
      renderCell: renderStartDate,
      // disableSelectionOnClick: true,
      sortable: true,
  
      disableExport: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "endDate",
      headerName: "End Date",
      flex: 1,
      renderCell: renderEndDate,
      // disableSelectionOnClick: true,
      sortable: true,
  
      disableExport: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "action",
      headerName: "Action",
      renderCell: renderAction,
      flex: 1,
      disableSelectionOnClick: true,
      disableExport: true,
      sortable: false,
    },
  ];
  const rows = [];
  if (vendors?.vendors) {
    vendors.vendors.forEach((item) => {
      rows.push({
        id: "#" + item._id,
        email: item.email,
        name: item.name,
        screenCount: item.totalScreens,
        subAmt: "0.00",
        subStatus: 1,
        duration: item.duration + " Months",
        reports: item,
        action: item,
        startDate: item.subscription,
        endDate: item.subscription,
        reseller: item.reseller ? item.reseller.email : "--",
      });
    });
  }

  const options = [];
  if (resellers?.reseller) {
    resellers.reseller.forEach((item) => {
      options.push({
        value: item._id,
        label: item.name,
      });
      // options.push({
      //   value:item._id,
      //   label:item.name
      // });
    });
  }

  return (
    <Fragment>
      <AddVendor
        open={open}
        setOpen={setOpen}
        setAlert={setAlert}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        type={type}
        setType={setType}
        setIsRefresh={setIsRefresh}
        options={options}
      />

{deleteModal && (
        <DeleteConfirmation
          setDeleteModal={setDeleteModal}
          callbackFunction={terminateAccount}
          text="Are you sure you want to delete?"
          yes={"Yes Delete"}
        />
      )}
      <div className="col-12">
        <div className="card">
          <Card.Header className="d-block">
            <Card.Title>
              Trendy Clients
              <Button
                style={{ float: "right" }}
                className="mr-2"
                variant="danger light"
                onClick={(e) => handleOpen(e)}
              >
                + Add Client
              </Button>
            </Card.Title>
            <Card.Text className="mb-0 subtitle">
              Clients Directly associated with Trendy
            </Card.Text>
          </Card.Header>
          <div className="card-body">
            <div className="w-100">
              <DataGrid
                getRowHeight={() => "auto"}
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  Toolbar: CustomToolbar,
                  LoadingOverlay: LinearProgress,
                  Pagination: CustomPagination,
                }}
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                pagination
                disableSelectionOnClick
                autoHeight={true}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};
export default connect(mapStateToProps)(Order);
