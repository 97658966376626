import React, { useState } from 'react'
/// React router dom
import {Switch, Route } from 'react-router-dom'
/// Css
import './index.css'
import './chart.css'
import './step.css'
import Error404 from './pages/Error404';
/// Layout
import Nav from './layouts/nav'
import Footer from './layouts/Footer'

/// Dashboard
import Home from "./components/Dashboard/Home";

import Task from './components/Dashboard/Task';

/// App
import AppProfile from './components/AppsMenu/AppProfile/AppProfile'
//import GoolglePlace from './pages/Item/Place';


/// Widget
import Widget from './pages/Widget'


/// Pages
import Registration from './pages/Registration'
import Login from './pages/Login'


//Scroll To Top
import ScrollToTop from './layouts/ScrollToTop';
//Users
import UpdateProfile from './components/AppsMenu/AppProfile/UpdateProfile';
import Order from './pages/Clients/index';
import reports from './pages/Clients/reports';
import clientsReports from './pages/Clients/clients-reports';
import Reseller from './pages/Reseller';
import ResellerDetail from './pages/Reseller/ResellerDetail'


const Markup = () => {
  let path = window.location.pathname
  path = path.split('/')
  path = path[path.length - 1]
  let pagePath = path.split('-').includes('page')
  const [activeEvent, setActiveEvent] = useState(!path)

  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },

	  { url: 'task', component: Task },

    /// Apps
    { url: 'profile', component: AppProfile },



    /// Widget
    { url: 'widget-basic', component: Widget },

    /// pages
    { url: 'page-register', component: Registration },
    // { url: 'page-lock-screen', component: LockScreen },
    { url: 'page-login', component: Login },


    /// pages
    { url: 'page-register', component: Registration },
    { url: 'page-login', component: Login },

    //Category
    { url : 'profile/update', component : UpdateProfile},
    { url : 'clients', component : Order },
    { url : 'clients-report/:report/:vendorId', component:reports},
    { url : 'clients-report', component : clientsReports },
    { url : 'resellers', component : Reseller},
    { url : 'reseller/:id', component : ResellerDetail},
    { url : "404", component : Error404},
    { url : "*", component : Error404}


  ]

  return (
       <> 
          <div
            id={`${!pagePath ? 'main-wrapper' : ''}`}
            className={`${!pagePath ? 'show' : 'mh100vh'}`}
          >
            {!pagePath && (
              <Nav
                onClick={() => setActiveEvent(!activeEvent)}
                activeEvent={activeEvent}
                onClick2={() => setActiveEvent(false)}
                onClick3={() => setActiveEvent(true)}
              />
            )}
            <div
              className={` ${!path && activeEvent ? 'rightside-event' : ''} ${
                !pagePath ? 'content-body' : ''
              }`}
            >
              <div
                className={`${!pagePath ? 'container-fluid' : ''}`}
                style={{ minHeight: window.screen.height - 60 }}
              >
                <Switch>
                  {routes.map((data, i) => (
                    <Route
                      key={i}
                      exact
                      path={`/${data.url}`}
                      component={data.component}
                    />
                  ))}
                </Switch>
              </div>
            </div>
            {!pagePath && <Footer />}
          </div>
         <ScrollToTop />
       </>
  )
}

export default Markup
