import React, { Fragment, useEffect, useState } from "react";

import { Link } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux';

import useForm from '../../../../services/formHook';
import {vendorValidation} from '../../../../services/validation';
import { UPDATE_VENDOR_RESET } from '../../../../store/Constants/index';
import { updateVendor, getVendor } from "../../../../store/actions/VendorAction";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Select from "react-select";
import { usePlacesWidget } from "react-google-autocomplete";
import Form from "react-bootstrap/Form";





const UpdateProfile = ({ history, auth}) => {
    const [add, setAdd] = useState({});
    const [addressError, setAddressError] = useState("");
    const { ref: bootstrapRef } = usePlacesWidget({
        //apiKey: process.env.REACT_APP_GOOGLE,
        apiKey : "AIzaSyA_JO9H6JEScutFurdvFw1t-v31GIf2Q2o",
        onPlaceSelected: (place) => {handleLocation(place)},
        options: {
            types: ["(regions)"],
            componentRestrictions: { country: ["IN", 'AE'] },
        },
    });
   const [isRefresh, setIsRefresh]  = useState(false)
   const dispatch = useDispatch();
   const {
    success,
    loading, 
    vendor
} = useSelector((state) => state.vendorDetail);

   const { categories } = useSelector((state) => state.categories);
   const { loading:updateLoading, error:updateError, isUpdated } = useSelector((state) => state.updateVendor)
   useEffect(() => {

    if(success){
        handleUpdate({
            id:vendor._id,
            update:true,
            name:vendor.users.name,
            businessName_en:vendor.businessName_en,
            businessName_ar:vendor.businessName_ar,
            description_en:vendor.description_en ? vendor.description_en : "",
            description_ar:vendor.description_ar ? vendor.description_ar : "",
            email:vendor.users.email,
            phoneNumber:vendor.users.mobile,
            countryCode:{
                value : vendor.users ? vendor.users.countryCode : "",
                label : vendor.users ? vendor.users.countryCode : "",
                color : "#00B8D9", 
                isFixed: true},
            coverPic:process.env.REACT_APP_IMAGE_BASE_URL+vendor.users.avatar.url,
            address:
                    {
                        address :  vendor.address.address,
                        latitude :  vendor.address.latitude,
                        longitude : vendor.address.longitude
                    },
            cate:{
                value : vendor.category ? vendor.category._id : "",
                label : vendor.category ? vendor.category.name_en : "",
                color : "#00B8D9", 
                isFixed: true}
        })
        setAdd({address :  vendor.address.address,
            latitude :  vendor.address.latitude,
            longitude : vendor.address.longitude})
    }

    if (isUpdated) {
        dispatch({ type: UPDATE_VENDOR_RESET });
        history.push({
            pathname: '/profile',
            state: { message: "Profile has been updated successfully !" }
      }); 
    }

    dispatch(getVendor(auth.data._id, auth.idToken))
    setIsRefresh(false)
}, [dispatch, auth, isRefresh, history,success, isUpdated, updateError])
    const initialValues = {
        name:vendor.users ? vendor.users.name : "",
        businessName_en:vendor.businessName_en,
        description_en:vendor.description_en ? vendor.description_en : "",
        description_ar:vendor.description_ar ? vendor.description_ar : "", 
        businessName_ar:"",
        email:'',
        phoneNumber:'',
        countryCode:'',
        paymentDetails:{},
        address:"",
        countryCode:{
            value : "+965",
            label : "+965",
            color : "#00B8D9", 
            isFixed: true},
        coverPic:"",
        address:{
            address :  vendor.address ? vendor.address.address   : "",
            latitude :  vendor.address ? vendor.address.latitude : "",
            longitude : vendor.address ? vendor.address.longitude : ""
        },
        cate:{
            value : vendor.category ? vendor.category._id : "",
            label : vendor.category ? vendor.category.name_en : "",
            color : "#00B8D9", 
            isFixed: true}

    }

    const {values, errors, handleChange, handleSubmit, handleUpdate} = useForm(initialValues,updateVendorFunc,vendorValidation)
    function updateVendorFunc(){
        if(!add.address || add.address == ""){
            setAddressError("Invalid Address");
            return;
        }else{
            setAddressError("")
        }
        const myForm = new FormData();
        myForm.set('name',values.name);
        myForm.set('businessName_en',values.businessName_en);
        myForm.set('businessName_ar',values.businessName_ar);
        myForm.set('description_en',values.description_en);
        myForm.set('description_ar',values.description_ar);
        myForm.set('address',add.address);
        myForm.set('latitude',add.latitude);
        myForm.set('longitude',add.longitude);
        myForm.set('email',values.email);
        myForm.set('mobile',values.phoneNumber);
        myForm.set('countryCode',values.countryCode.value);
        myForm.set('coverPic',values.coverPic);
        myForm.set('categoryType',values.cate.value);
        dispatch(updateVendor(myForm, auth.data._id, auth.idToken));
    }

    const handleSelect = (e) => {
        handleUpdate({...values,['menu']:e})
    }

    const handleLocation = (place) => {

        let location = JSON.parse(JSON.stringify(place?.geometry?.location));
        const adres = {
            address : place.formatted_address,
            latitude : location.lat,
            longitude : location.lng
        }
        setAdd(adres);
    }

    const cateRows = [];
    if(categories){
        categories.forEach((item) => {
            cateRows.push({
                value : item._id,
                label : item.name_en,
                color : "#00B8D9", 
                isFixed: true


            });
        });   
    }
    const countryCodeList = [
        { value: '+965', label: "+965" }
    ];

    const handleCountryCode = (e) => {
        let data = {...values,['countryCode']:e}
        handleUpdate(data)
    }

    return (
      <Fragment>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || updateLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
         <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Update Profile</h4>
            </div>
            <div className="card-body">
                {updateError && <h5 className="alert alert-danger">{updateError}</h5>}
                <div className="basic-form">
                    
                    <form 
                        onSubmit={handleSubmit}
                        autoComplete="off"
                    >
                        <div className="row">
                        
                            <div className="col-md-6">
                                <div
                                    className={`form-group ${
                                        errors.name
                                            ? "input-danger-o"
                                            : "input-success-o"
                                    }`}
                                >
                                    <label className="text-label">
                                        Name
                                    </label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fa fa-user" />{" "}
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            placeholder="Enter a name.."
                                            name="name"
                                            onChange={handleChange}
                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                            value={values.name}
                                        />
                                        <div
                                            id="val-username1-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        >
                                            {errors.name && errors.name}
                                        </div>

                                        <div
                                            id="val-username1-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div
                                    className={`form-group ${
                                        errors.email
                                            ? "input-danger-o"
                                            : "input-success-o"
                                    }`}
                                >
                                    <label className="text-label">
                                        Email
                                    </label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className='input-group-text'>@</span>
                                        </div>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="name"
                                            placeholder="Enter a Email.."
                                            name="email"
                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                            onChange={handleChange}
                                            value={values.email}
                                        />
                                        <div
                                            id="val-username1-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        >
                                            {errors.email && errors.email}
                                        </div>

                                        <div
                                            id="val-username1-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div
                                    className={`form-group ${
                                        errors && errors.name_en
                                            ? "input-danger-o"
                                            : "input-success-o"
                                    }`}
                                >
                                    <label className="text-label">
                                        Business Name
                                    </label>
                                    <div className="input-group-md">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="businessName_en"
                                            placeholder="Enter a name.."
                                            name="businessName_en"
                                            onChange={handleChange}
                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                            value={values && values.businessName_en ? values.businessName_en : ''}
                                        />
                                        <div
                                            id="val-username1-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        >
                                            {errors && errors.businessName_en}
                                        </div>

                                        <div
                                            id="val-username1-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div
                                    className={`form-group ${
                                        errors && errors.businessName_ar
                                            ? "input-danger-o"
                                            : "input-success-o"
                                    }`}
                                >
                                    <label className="text-label" style={{textAlign:'right', width:'100%'}}>
                                    الاسم التجاري
                                    </label>
                                    <div className="input-group-md">
                                        <input
                                            type="text"
                                            className="form-control"
                                            style={{textAlign:'right'}}
                                            id="businessName_ar"
                                            placeholder="أدخل اسم عمل"
                                            name="businessName_ar"
                                            onChange={handleChange}
                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                            value={values && values.businessName_ar ? values.businessName_ar : ''}
                                        />
                                        <div
                                            id="val-username1-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        >
                                            {errors && errors.businessName_ar}
                                        </div>

                                        <div
                                            id="val-username1-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div
                                    className={`form-group ${
                                        errors && errors.description_en
                                            ? "input-danger-o"
                                            : "input-success-o"
                                    }`}
                                >
                                    <label className="text-label">
                                        Description
                                    </label>
                                    <div className="input-group-md">
                                        <textarea
                                            rows={10}
                                            className="form-control"
                                            id="description_en"
                                            placeholder="Enter a Description.."
                                            name="description_en"
                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                            onChange={handleChange}
                                        >
                                            {values && values.description_en ? values.description_en : ''}
                                        </textarea>
                                        <div
                                            id="val-username1-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        >
                                            {errors && errors.description_en}
                                        </div>

                                        <div
                                            id="val-username1-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div
                                    className={`form-group ${
                                        errors && errors.description_ar
                                            ? "input-danger-o"
                                            : "input-success-o"
                                    }`}
                                >
                                    <label className="text-label" style={{textAlign:'right', width:'100%'}}>
                                    وصف
                                    </label>
                                    <div className="input-group-md">
                                        <textarea
                                            rows={10}
                                            style={{textAlign:'right'}}
                                            className="form-control"
                                            id="description_ar"
                                            placeholder="أدخل وصفًا"
                                            name="description_ar"
                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                            onChange={handleChange}
                                        >
                                            {values && values.description_ar ? values.description_ar : ''}
                                        </textarea>
                                        <div
                                            id="val-username1-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        >
                                            {errors && errors.description_ar}
                                        </div>

                                        <div
                                            id="val-username1-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                
                                <div >
                                    <Form.Group controlId="formBasicEmail" className={` ${
                                        errors.countryCode
                                            ? "input-danger-o"
                                            : "input-success-o"
                                    }`}>
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control 
                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                            type="text" ref={bootstrapRef} autoComplete="off" defaultValue={values.address.address} />
                                    </Form.Group>
                                    <div
                                            id="val-username1-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        >
                                            {!add.address ? "Invalid Address"  :""}
                                        </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div
                                    className={`form-group ${
                                        errors.countryCode
                                            ? "input-danger-o"
                                            : "input-success-o"
                                    }`}
                                >
                                    <label className="text-label">
                                        Country Code
                                    </label>
                                    
                                        <Select
                                            closeMenuOnSelect={true}
                                            value={values.countryCode}
                                            onChange={(e) => {handleCountryCode(e)}}
                                            name="cate"
                                            id="cate"
                                            options={countryCodeList}
                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                        />
                                        <div
                                            id="val-username1-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        >
                                            {errors.countryCode && errors.countryCode}
                                        </div>

                                        <div
                                            id="val-username1-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        />
                                    {/* </div> */}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div
                                    className={`form-group ${
                                        errors.phoneNumber
                                            ? "input-danger-o"
                                            : "input-success-o"
                                    }`}
                                >
                                    <label className="text-label">
                                        Phone Number
                                    </label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fa fa-phone" />{" "}
                                            </span>
                                        </div>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="phoneNumber"
                                            placeholder="Enter a Phone.."
                                            name="phoneNumber"
                                            onChange={handleChange}
                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                            value={values.phoneNumber}
                                        />
                                        <div
                                            id="val-username1-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        >
                                            {errors.phoneNumber && errors.phoneNumber}
                                        </div>

                                        <div
                                            id="val-username1-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            <div className='col-xl-6'>
                                <div
                                    className={`form-group ${
                                        errors.cate
                                            ? "input-danger-o"
                                            : "input-success-o"
                                    }`}
                                >
                                    <label className="text-label">
                                        Select Categories
                                    </label>
                                    <div>
                                    <Select
                                        closeMenuOnSelect={true}
                                        value={values.cate}
                                        onChange={(e) => {handleSelect(e)}}
                                        name="cate"
                                        id="cate"
                                        options={cateRows}
                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                    />
                                    <div
                                        id="val-username1-error"
                                        className="invalid-feedback animated fadeInUp"
                                        style={{ display: "block" }}
                                    >
                                        {errors.cate && errors.cate}
                                    </div>

                                    {/* <CustomClearIndicator></CustomClearIndicator> */}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div
                                    className={`form-group ${
                                        errors.coverPic
                                            ? "input-danger-o"
                                            : "input-success-o"
                                    }`}
                                >
                                    <label className="text-label">
                                        Select Cover Pic
                                    </label>
                                    <div className="input-group">
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="coverPic"
                                        placeholder="Choose a file.."
                                        name="coverPic"
                                        onChange={handleChange}
                                    />
                                        <div
                                            id="val-username1-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        >
                                            {errors.coverPic && errors.coverPic}
                                        </div>

                                        <div
                                            id="val-username1-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        />
                                    </div>
                                    {
                                        values.coverPic && <img alt="preview" src={values.coverPic} style={{width:'100px'}} />
                                    }
                                </div>
                                
                                
                            </div>
                            
                        </div>
                        <button
                            type="submit"
                            className="btn mr-2 btn-primary"
                            disabled={loading}
                        >
                            Update
                        </button>
                        <button
                            onClick={history.goBack}
                            type="button"
                            className="btn btn-light"
                        >
                        Back
                        </button>
                    </form>  
                </div>
            </div>
          </div>
        </div>

      </Fragment>
   );
};

const mapStateToProps = (state) => {
   return {
       auth: state.auth.auth,
   };
 };
 export default connect(mapStateToProps)(UpdateProfile);
