import {
    DASHBOARD_REQUEST,
    DASHBOARD_SUCCESS,
    DASHBOARD_FAIL,
    CLEAR_ERRORS
} from '../Constants/Dashboard';

export const dashBoardReducer = (state = {dashboardData:{}}, action) => {
    switch(action.type){
        case DASHBOARD_REQUEST:
            return{
                loading:true,
                dashboardData:{}
            }
        case DASHBOARD_SUCCESS:
            return{
                loading:false,
                dashboardData:action.payload.data
            }
        case DASHBOARD_FAIL:
            return{
                loading:false,
                error:action.payload

            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error:null
            }
        default:
            return state
    }
}