import React, { Fragment, useEffect, useState } from "react";
import Rating from "@mui/material/Rating";
import { Dropdown, Badge, Button, Card } from "react-bootstrap";
import {humanReadableFormattedDateString} from '../../../utils/helper'
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import { connect, useDispatch, useSelector } from "react-redux";
import { ResellerList, deleteReseller } from "../../../store/actions/ResellerAction";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../components/NoRows";
import QuickSearchToolbar from "../../components/QuickSearchToolBar";
import { GridToolbarContainer } from "@mui/x-data-grid";
import AddReseller from "./AddReseller.jsx";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { ADD_RESELLER_RESET } from "../../../store/Constants";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <QuickSearchToolbar />
    </GridToolbarContainer>
  );
}

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

const Order = ({ auth }) => {
  const history = useHistory();
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const [isRefresh, setIsRefresh] = useState(false);
  const [type, setType] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { loading, error, resellers } = useSelector((state) => state.resellers);
  const { loading:deleteLoading, error:deleteError, isDeleted } = useSelector((state) => state.ResellerDeleteReducer);

  const setAlert = (type, msg) => {
    if (type == "error") {
      toast.error("❌ " + msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (type == "success") {
      toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleOpen = (e) => {
    setSelectedItem(null);
    setType(null);
    setOpen(true);
  };

  const handleDelete = (e, item) => {
    e.preventDefault();
    dispatch(deleteReseller(auth.idToken, item));
  }

  useEffect(() => {
    if (error) {
      toast.error("❌ " + error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    if (isDeleted) {
      toast.success("Reseller has been deleted successfully !!!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    dispatch({ type: ADD_RESELLER_RESET });
    dispatch(ResellerList(auth.idToken, ""));
    setIsRefresh(false);
  }, [isRefresh, isDeleted]);

  const renderAction = (params) => {
    const { value } = params;
    return (
      <Dropdown className="dropdown-action">
        <Dropdown.Toggle variant="" className="i-false">
          {svg1}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {/* <Dropdown.Item
            onClick={(e) => {
              handleEdit(e, value);
            }}
          >
            Renew Subscription
          </Dropdown.Item> */}
          <Dropdown.Item
            onClick={(e) => {handleDelete(e, value._id)}}
          >Terminate Account</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderStatus = (params) => {
    const { value } = params;
    return (
        <Button className="btn-sm mr-2" variant="success light">
          View Clients
        </Button>
    );
  };

  const renderVendor = (params) => {
    const {value} = params;
    return <Link to={`/reseller/${value._id}`}>{value.vendors.length}</Link>
    let txt = "--";
    if(value && value.length > 0){
      txt = value.map((item) => {
        return <li>{item.email}</li>
      });
      txt = <ul>{txt}</ul>
    }

    return txt;

  }

  const handleEdit = (e, item) => {
    // e.preventDefault();
    // setType("edit");
    // setSelectedItem(item)
    // console.log("edit", item);
    // setOpen(true)
  };
  //End

  const columns = [
    { field: "id", headerName: "Client ID", flex: 1,disableSelectionOnClick:true, },
    { field: "email", headerName: "Email", flex: 1, disableSelectionOnClick:true, },
    { field: "name", headerName: "Name", flex: 1, disableSelectionOnClick:true, },
    { field: "clients", headerName: "Clients", flex: 1, disableSelectionOnClick:true, renderCell:renderVendor },
    // { field: "revenue", headerName: "Revenue/Month", flex: 1, disableSelectionOnClick:true, },
    // { field: "commission", headerName: "Commission%",flex: 1, disableSelectionOnClick:true},
    // { field: "viewClients", headerName: "View Clients", flex: 1, disableSelectionOnClick:true,renderCell:renderStatus },
    { field: "action", headerName: "Action", renderCell: renderAction,flex:1, disableSelectionOnClick:true, disableExport:true, sortable:false},
  ];
  const rows = [];
  if (resellers.reseller) {
    resellers.reseller.forEach((item) => {
      rows.push({
        id: "#" + item._id,
        email: item.email,
        name: item.name,
        clients:item,
        revenue:"100$",
        commission: item.comission ? item.comission+"%" : 0+"%",
        createdAt: humanReadableFormattedDateString(item.createdAt),
        viewClients:item,
        action: item,
      });
    });
  }

  return (
    <Fragment>
      <AddReseller
        open={open}
        setOpen={setOpen}
        setAlert={setAlert}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        type={type}
        setType={setType}
        setIsRefresh={setIsRefresh}
      />
      <div className="col-12">
        <div className="card">
          <Card.Header className="d-block">
            <Card.Title>
              Trendy Resellers
              <Button
                style={{ float: "right" }}
                className="mr-2"
                variant="danger light"
                onClick={(e) => handleOpen(e)}
              >
                + Reseller
              </Button>
            </Card.Title>
            <Card.Text className="mb-0 subtitle">
              Reseller Directly associated with Trendy
            </Card.Text>
          </Card.Header>
          <div className="card-body">
            <div className="w-100">
              <div style={{ display: "flex", height: "100%" }}>
                <DataGrid
                  components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                    Toolbar: CustomToolbar,
                    LoadingOverlay: LinearProgress,
                    Pagination: CustomPagination,
                  }}
                  rows={rows}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  pagination
                  disableSelectionOnClick
                  autoHeight={true}
                  loading={loading || deleteLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};
export default connect(mapStateToProps)(Order);
