import {
  DASHBOARD_REQUEST,
  DASHBOARD_SUCCESS,
  DASHBOARD_FAIL,
  CLEAR_ERRORS,
} from "../Constants/Dashboard";
import { BASE_URL } from "../Utils";

import axios from "axios";
export const DashboardAction = (token) => async (dispatch) => {
  try {
    dispatch({
      type: DASHBOARD_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const data = await axios.get(
      `${BASE_URL}admin/auth/dashboard`,
      config
    );
    dispatch({ type: DASHBOARD_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: DASHBOARD_FAIL,
      payload: err.response,
    });
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
