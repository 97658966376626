import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { AuthReducer } from "./reducers/AuthReducer";
import { UserListReducer, ChangePassword } from "./reducers/UserReducer";

import {
  VendorListReducer,
  AddVendorReducer,
  updateVendorReducer,
  VendorDetailReducer,
  VendorReportReducer,
} from "./reducers/VendorReducer";
import { dashBoardReducer } from "./reducers/Dashboard";
import todoReducers from "./reducers/Reducers";
import { reducer as reduxFormReducer } from "redux-form";
import {
  ResellerListReducer,
  AddResellerReducer,
  updateResellerReducer,
  ResellerDeleteReducer,
  ResellerDetailReducer
} from "./reducers/ResellerReducer";

const middleware = applyMiddleware(thunk);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  auth: AuthReducer,
  todoReducers,
  users: UserListReducer,
  dashData: dashBoardReducer,
  ChangePassword,
  vendorDetail: VendorDetailReducer,
  vendors: VendorListReducer,
  AddVendorReducer,
  updateVendor: updateVendorReducer,
  VendorReportReducer,
  resellers: ResellerListReducer,
  AddResellerReducer,
  updateResellerReducer,
  ResellerDeleteReducer,
  reseller:ResellerDetailReducer,
  form: reduxFormReducer,
});

//const store = createStore(rootReducers);

export const store = createStore(reducers, composeEnhancers(middleware));
