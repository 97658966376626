import {useState, useEffect} from 'react';


const useForm = (initialValues, callback, validate) => {
    const [values, setValues] = useState(initialValues)
	const [errors, setErrors] = useState({})
	const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        if (errors !== undefined && Object.keys(errors).length === 0 && isSubmitting) {
          callback();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [errors]);

    const handleUpdate = (data) => {
        setValues(data);
    }
    const handleChange = (event) => {

        if(event.target.name === 'shortAudio' || event.target.name === 'longAudio' || event.target.name === 'cover'){
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setValues({...values,[event.target.name]:reader.result});
                }
            };
            reader.readAsDataURL(event.target.files[0]);
        }else if(event.target.name === 'isPremium' || event.target.name === 'isHighlighted'){
            setValues({...values,[event.target.name]:event.target.checked});
        }else{
            setValues({...values,[event.target.name]:event.target.value});
        }
       // console.log(values);
        //setErrors(validate(values,pass));
    }

    const handleSubmit = (event) => {
        if(event){
            event.preventDefault();
        }

        setErrors(validate(values));
        setIsSubmitting(true);
    }
    return {
        handleUpdate,
        handleChange,
        handleSubmit,
        values,
        errors
    }
}

export default useForm;