import React, { Fragment, useEffect, useState } from "react";
import Rating from '@mui/material/Rating';
import { Dropdown, Badge, Button, Card } from "react-bootstrap";
import { connect, useDispatch, useSelector } from 'react-redux';
import { VendorReport } from "../../../store/actions/VendorAction";
import 'react-toastify/dist/ReactToastify.css'
import {useParams} from 'react-router-dom';
import MediaReport from "./MediaReport";
import AuditLogs from './AuditLogs'
import UptimeReport from "./UptimeReport";

const Report = ({auth}) => {
    const {vendorId, report} = useParams();
    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    let fYear = firstDay.getFullYear();
    let fMonth = firstDay.getMonth()+1;
    let fDate = firstDay.getDate();
    var lastDay = new Date(y, m + 1, 0);
    let lYear = lastDay.getFullYear();
    let lMonth = lastDay.getMonth()+1;
    let lDate = lastDay.getDate();


    const svg1 = (
      <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect x="0" y="0" width="24" height="24"></rect>
          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
        </g>
      </svg>
    );
   const [isRefresh, setIsRefresh]  = useState(false);
   const [startDate, setStartDate] = useState(`${fYear}-${fMonth < 10 ? '0'+fMonth : fMonth}-${fDate < 10 ? '0'+fDate : fDate}`)
   const [endDate, setEndDate] = useState(`${lYear}-${lMonth < 10 ? '0'+lMonth : lMonth}-${lDate < 10 ? '0'+lDate : lDate}`)
   const [queryString, setQueryString]  = useState(`vendorId=${vendorId}&limit=1000`);
   
   const dispatch = useDispatch();
    const { 
      loading, 
      error,
      reports
  } = useSelector((state) => state.VendorReportReducer);

  console.log("reports", reports)


    const handleSubmit = (e) => {
      e.preventDefault();
      let qstring = `vendorId=${vendorId}&startDate=${startDate}&endDate=${endDate}&limit=1000`
      setQueryString(qstring)
    }
    useEffect(() => {
        // if(error){
        //     toast.error('❌ '+error, {
        //         position: 'top-right',
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //     })
        // }
        dispatch(VendorReport(auth.idToken,report,queryString))
    }, [vendorId, report,queryString])
    
    
   return (
      <Fragment>
         <div className="col-12">
          <div className="card">
            <Card.Header className='d-block'>
                <Card.Title style={{marginBottom:'25px'}}>
                  {report && report == 'mediaReport' ? 'Media Report' : ''}
                  {report && report == 'uptimeReport' ? 'Uptime Report' : ''}
                  {report && report == 'auditLogs' ? 'Audit Logs' : ''}
                </Card.Title>
                {/* <Card.Text className='mb-0 subtitle'>
                  Clients Directly associated with Trendy
                </Card.Text> */}
                <div className="basic-form">
                    <form 
                        onSubmit={handleSubmit}
                        autoComplete="off"
                    >
                        <div className="row">
                            <div className="col-md-5">
                                <div
                                    className={`form-group`}
                                >
                                    <label className="text-label">
                                        Start Date
                                    </label>
                                    <div className="input-group-md">
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="startDate"
                                            placeholder="Enter a name.."
                                            name="startDate"
                                            onChange={(e) => {setStartDate(e.target.value)}}
                                            value={startDate}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div
                                    className={`form-group`}
                                >
                                    <label className="text-label">
                                        End Date
                                    </label>
                                    <div className="input-group-md">
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="endDate"
                                            placeholder="Enter a name.."
                                            name="endDate"
                                            onChange={(e) => {setEndDate(e.target.value)}}
                                            value={endDate}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div
                                    className={`form-group`}
                                >
                                    <button
                                        type="submit"
                                        className="btn mr-2 btn-primary"
                                        //disabled={loading}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                
            </Card.Header>
            <div className="card-body">
              <div className="w-100">
                  
                  <div style={{ display: 'flex', height: '100%' }}>
                    {
                      report && report == 'mediaReport' && <MediaReport data={reports} loading={loading}/>
                    }
                    {
                      report && report == 'auditLogs' && <AuditLogs data={reports} loading={loading}/>
                    }
                    {
                      report && report == 'uptimeReport' && <UptimeReport data={reports} loading={loading}/>
                    }
                  </div>
                  
              </div>
            </div>
          </div>
        </div>

      </Fragment>
   );
};

const mapStateToProps = (state) => {
   return {
       auth: state.auth.auth,
   };
 };
 export default connect(mapStateToProps)(Report);
