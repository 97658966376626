import { lazy, Suspense, useEffect } from "react";
/// Components
import Index from "./jsx/index";
import { connect, useDispatch } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
// action
import { checkAutoLogin } from "./services/AuthService";
import { isAuthenticated,isVerified } from "./store/selectors/AuthSelectors";
// import { getProfile } from './store/actions/AuthActions';
// import { LOGOUT_ACTION } from './store/actions/AuthActions';
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import "./css/custom.css";
import Verification from './jsx/pages/VerificationBkp';

const SignUp = lazy(() => import("./jsx/pages/Registration"));
const ForgotPassword = lazy(() => import("./jsx/pages/ForgotPassword"));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
  });
});
function App(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    // if(!props.isAuthenticated){
    //     localStorage.removeItem('users');
    //     props.history.push('/login');
    //     return {
    //         type: LOGOUT_ACTION,
    //     };
    // }
    checkAutoLogin(dispatch, props.history);
  }, [dispatch, props.history]);

  let routes = (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/page-register" component={SignUp} />
      <Route path="/page-forgot-password" component={ForgotPassword} />
    </Switch>
  );
  if (props.isAuthenticated) {
    if(props.isVerified){
        return (
            <>
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                }
                >
                    <Index />
                </Suspense>
            </>
        );

    }else{
        return  <div className="vh-100">
            <Suspense fallback={
                <div id="preloader">
                    <div className="sk-three-bounce">
                        <div className="sk-child sk-bounce1"></div>
                        <div className="sk-child sk-bounce2"></div>
                        <div className="sk-child sk-bounce3"></div>
                    </div>
                </div>
            }
            >
                <Switch>
                    <Route path='/verification' component={Verification} />
                </Switch>
            </Suspense>
        </div>

    }
    // return (
    //   <>
    //     <Suspense
    //       fallback={
    //         <div id="preloader">
    //           <div className="sk-three-bounce">
    //             <div className="sk-child sk-bounce1"></div>
    //             <div className="sk-child sk-bounce2"></div>
    //             <div className="sk-child sk-bounce3"></div>
    //           </div>
    //         </div>
    //       }
    //     >
    //       <Index />
    //     </Suspense>
    //   </>
    // );
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {routes}
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    isVerified: isVerified(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));
