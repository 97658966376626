import React, {useEffect, useState} from "react";
import LinearProgress from '@mui/material/LinearProgress';
import { Button, Modal } from "react-bootstrap";
import useForm from '../../../services/formHook';
import {addResellerValidation} from '../../../services/validation';
import { addReseller, updateReseller} from "../../../store/actions/ResellerAction";
import { connect, useDispatch, useSelector } from 'react-redux';
import { ADD_RESELLER_RESET, UPDATE_RESELLER_RESET } from "../../../store/Constants";
import {toast } from 'react-toastify'

const AddResellerComp = ({open, setOpen,auth, setAlert, selectedItem, setSelectedItem, type, setType, setIsRefresh}) => {

    const dispatch = useDispatch();
    const [err, setErr] = useState(null);
    const [sellerId, setSellerId] = useState(null);

    const {
        loading, 
        error, 
        success
    } = useSelector((state) => state.AddResellerReducer);
    const {
        loading:updateLoading, 
        error:updateError, 
        isUpdated
    } = useSelector((state) => state.updateResellerReducer)
    const initialValues = {
        name:"",
        email:"",
        //comission:0
        password:''
    }
    const {values, errors, handleChange, handleSubmit, handleUpdate} = useForm(initialValues,addVendorForm,addResellerValidation)
    function addVendorForm(e){
        const {name, email,
            //comission,
            password
        } = values
        if(type && type == 'edit'){
            dispatch(updateReseller({
                name, email, 
                //comission,
                resellerId:sellerId
            },auth.idToken))
        }else{
            dispatch(addReseller({
                name, email, 
                //comission
                password
            },auth.idToken))
        }
        setIsRefresh(true)
    }

    useEffect(() => {
        if(error){
            setErr(error)
        }
        if(updateError){
            setErr(updateError)
        }
        if(success){
            setOpen(false);
            setErr(null);
            dispatch({type:ADD_RESELLER_RESET});
            setAlert("success", 'Seller has been created successfully !!!')
        }
        if(isUpdated){
            setOpen(false);
            setErr(null);
            dispatch({type:UPDATE_RESELLER_RESET});
            setAlert("success", 'Seller has been updated successfully !!!')
        }
        if(selectedItem){
            handleUpdate({
                name:selectedItem.name,
                email:selectedItem.email,
                comission:selectedItem.comission,
            })
            setSellerId(selectedItem._id)
        }
    },[dispatch, auth, error, success,selectedItem, updateError, isUpdated])
   return (
        <Modal className="fade" show={open}>
            {loading ? <LinearProgress /> : '' }
            <Modal.Header>
                <Modal.Title>Add Reseller</Modal.Title>
                    <Button
                        variant=""
                        className="close"
                        onClick={() => {setOpen(false)}}
                    >
                        <span>&times;</span>
                    </Button>
                </Modal.Header>
            <Modal.Body>
                
                <div className="basic-form">
                    {err && <h6 className="alert alert-danger">{err}</h6>}
                    <form 
                        onSubmit={handleSubmit}
                        autoComplete="off"
                    >
                        <div className="row">
                            <div className="col-md-12">
                                <div
                                    className={`form-group ${
                                        errors && errors.name
                                            ? "input-danger-o"
                                            : "input-success-o"
                                    }`}
                                >
                                    <label className="text-label">
                                        UserName
                                    </label>
                                    <div className="input-group-md">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            placeholder="Enter a name.."
                                            name="name"
                                            onChange={handleChange}
                                            value={values && values.name}
                                        />
                                        <div
                                            id="val-username1-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        >
                                            {errors && errors.name}
                                        </div>

                                        <div
                                            id="val-username1-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div
                                    className={`form-group ${
                                        errors && errors.email
                                            ? "input-danger-o"
                                            : "input-success-o"
                                    }`}
                                >
                                    <label className="text-label">
                                        Email
                                    </label>
                                    <div className="input-group-md">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            placeholder="Enter a email"
                                            name="email"
                                            onChange={handleChange}
                                            value={values && values.email}
                                        />
                                        <div
                                            id="val-username1-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        >
                                            {errors && errors.email}
                                        </div>

                                        <div
                                            id="val-username1-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-md-12">
                                <div
                                    className={`form-group ${
                                        errors && errors.screen
                                            ? "input-danger-o"
                                            : "input-success-o"
                                    }`}
                                >
                                    <label className="text-label">
                                        Commision (%)
                                    </label>
                                    <div className="input-group-md">
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="comission"
                                            placeholder="Enter a Commission.."
                                            name="comission"
                                            onChange={handleChange}
                                            value={values && values.comission}
                                        />
                                        <div
                                            id="val-screen-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        >
                                            {errors && errors.comission}
                                        </div>

                                        <div
                                            id="val-screen-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        />
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-md-12">
                                <div
                                    className={`form-group ${
                                        errors && errors.password
                                            ? "input-danger-o"
                                            : "input-success-o"
                                    }`}
                                >
                                    <label className="text-label">
                                        Password
                                    </label>
                                    <div className="input-group-md">
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="password"
                                            placeholder="Enter a Password.."
                                            name="password"
                                            onChange={handleChange}
                                            value={values && values.password}
                                        />
                                        <div
                                            id="val-screen-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        >
                                            {errors && errors.password}
                                        </div>

                                        <div
                                            id="val-screen-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <button
                            type="submit"
                            className="btn mr-2 btn-primary"
                            disabled={loading}
                        >
                            Submit
                        </button>
                        <button
                            type="button"
                            onClick={() =>{setOpen(false)}}
                            className="btn btn-light"
                        >
                        Cancel
                        </button>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
   );
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth,
    };
};
export default connect(mapStateToProps)(AddResellerComp);

