/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import MfaPage from './MfaPage';

// import icon1 from "../../../images/icon1.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var deznav = document.querySelector(".deznav");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
    //deznav.addEventListener("click", toggleFunc);
  }
  state = {
    loveEmoji: false,
  };
  render() {
    /// Path
    let path = window.location.href;
    path = path.split("/");
    //path = path[path.length - 1];
    path = path[4] ? path[4] : path[3];
    /// Active menu
    
    let dashBoard = [""];
    let clients = ['clients'];
    let resellers = ['resellers'];
    let clientsReports = ['Client Reports']

    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
          <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
            <Link to="/" className="ai-icon" >
              <i className="flaticon-381-networking"></i>
              <span className="nav-text">Dashboard</span>
            </Link>
          </li>
          <li className={`${clients.includes(path) ? "mm-active" : ""}`}>
            <Link to="/clients" className="ai-icon" >
              <i className="flaticon-381-user"></i>
              <span className="nav-text">Clients</span>
            </Link>
          </li>
          <li className={`${clientsReports.includes(path) ? "mm-active" : ""}`}>
            <Link to="/clients-report" className="ai-icon" >
              <i className="flaticon-381-user"></i>
              <span className="nav-text">Clients Reports</span>
            </Link>
          </li>
          <li className={`${resellers.includes(path) ? "mm-active" : ""}`}>
            <Link to="/resellers" className="ai-icon" >
              <i className="flaticon-381-user"></i>
              <span className="nav-text">Resellers</span>
            </Link>
          </li>
          <li className={``}>
            <MfaPage />
          </li>
          </MM>
          
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
