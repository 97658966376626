import { USERS_LIST_REQUEST, USERS_LIST_SUCCESS, CLEAR_ERRORS, USERS_LIST_FAIL,
    CHANGEPASSWORD_REQUEST, CHANGEPASSWORD_SUCCESS, CHANGEPASSWORD_FAIL, CHANGEPASSWORD_RESET
} from "../Constants/UserConstants";


export const UserListReducer = (state = {users:[]}, action) => {
    switch(action.type){
        case USERS_LIST_REQUEST:
            return{
                loading:true,
                users:[]
            }
        case USERS_LIST_SUCCESS:
            return{
                loading:false,
                users:action.payload.data
            }
        case USERS_LIST_FAIL:
            return{
                loading:false,
                error:action.payload

            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error:null
            }
        default:
            return state
    }
}


export const ChangePassword = (state = {},action) => {
    switch(action.type){
        case CHANGEPASSWORD_REQUEST:
            return{
                ...state,
                loading:true
            }
        case CHANGEPASSWORD_SUCCESS:
            return{
                ...state,
                loading:false,
                isPasswordChanged:action.payload.data
            }
        case CHANGEPASSWORD_FAIL:
            return{
                ...state,
                loading:false,
                error:action.payload.data.message
            }
        case CHANGEPASSWORD_RESET:
            return{
                ...state,
                isPasswordChanged:false,
                error:null
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state
    }
}