import React, { Fragment, useEffect, useState } from "react";
import Rating from '@mui/material/Rating';
import { Dropdown, Badge, Button, Card } from "react-bootstrap";
import { DataGrid, 
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector, } from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import { connect} from 'react-redux';
import LinearProgress from '@mui/material/LinearProgress';
import CustomNoRowsOverlay from '../../components/NoRows';
import QuickSearchToolbar from '../../components/QuickSearchToolBar';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import 'react-toastify/dist/ReactToastify.css'



  function CustomToolbar() {
      return (
        <GridToolbarContainer>
          <QuickSearchToolbar />
          <GridToolbarExport />
        </GridToolbarContainer>
      );
  }

  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  
    return (
      <Pagination
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }

const AuditLogs = ({data, loading}) => {
    const svg1 = (
      <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect x="0" y="0" width="24" height="24"></rect>
          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
        </g>
      </svg>
    );


   const columns = [
    { field: 'title', headerName: 'Title',flex: 1, disableSelectionOnClick:true,},
    { field: 'name', headerName: 'Name', flex: 1, disableSelectionOnClick:true,},
    { field: 'email', headerName: 'Email', flex: 1, disableSelectionOnClick:true,},
    
  ];
    const rows = [];
    if(data){
       data.forEach((item) => {
          rows.push({
                id: item._id,
                name:item.vendor.name,
                title:item.title,
                email:item.vendor.email
            });
        });     
    }
    
   return (
    <DataGrid
        components={{
            NoRowsOverlay: CustomNoRowsOverlay,
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
            Pagination: CustomPagination,
        }}
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[10]}
        pagination
        disableSelectionOnClick
        autoHeight={true}
        loading={loading}
    />
   );
};

const mapStateToProps = (state) => {
   return {
       auth: state.auth.auth,
   };
 };
 export default connect(mapStateToProps)(AuditLogs);
