import React, { Fragment, useEffect, useState } from "react";
import Rating from "@mui/material/Rating";
import { Dropdown, Badge, Button, Card } from "react-bootstrap";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import { connect, useDispatch, useSelector } from "react-redux";
import { VendorList, orderStatus } from "../../../store/actions/VendorAction";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../components/NoRows";
import QuickSearchToolbar from "../../components/QuickSearchToolBar";
import { GridToolbarContainer } from "@mui/x-data-grid";
import AddVendor from "./AddVendor.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { ADD_VENDOR_RESET, GET_VENDOR_REPORT_RESET } from "../../../store/Constants";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <QuickSearchToolbar />
    </GridToolbarContainer>
  );
}

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

const ClientsReports = ({ auth }) => {
  const history = useHistory();
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const [isRefresh, setIsRefresh] = useState(false);
  const [type, setType] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { loading, error, vendors } = useSelector((state) => state.vendors);

  const setAlert = (type, msg) => {
    if (type == "error") {
      toast.error("❌ " + msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (type == "success") {
      toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleOpen = (e) => {
    setSelectedItem(null);
    setType(null);
    setOpen(true);
  };

  const handleReport = (e, type) => {
    e.preventDefault();
    console.log(type);
    history.push(type);
  };
  useEffect(() => {
    console.log("sdsd");
    if (error) {
      toast.error("❌ " + error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    dispatch({ type: ADD_VENDOR_RESET });
    dispatch({type:GET_VENDOR_REPORT_RESET})
    dispatch(VendorList(auth.idToken, ""));
    setIsRefresh(false);
  }, []);

  const mediaReport = (params) => {
    const {value} = params;
    return <Button
    style={{ float: "right" }}
    className="mr-2"
    variant="danger light"
    onClick={(e) => handleReport(e, `/clients-report/mediaReport/${value._id}`)}
  >
    View Media Report
  </Button>
  }
  const uptimeReport = (params) => {
    const {value} = params;
    return <Button
    style={{ float: "right" }}
    className="mr-2"
    variant="danger light"
    onClick={(e) => handleReport(e, `/clients-report/uptimeReport/${value._id}`)}
  >
    View Uptime Report
  </Button>
  }
  const auditReport = (params) => {
    const {value} = params;
    return <Button
    style={{ float: "right" }}
    className="mr-2"
    variant="danger light"
    onClick={(e) => handleReport(e, `/clients-report/auditLogs/${value._id}`)}
  >
    View Audit Logs
  </Button>
  }



  //End

  const columns = [
    { field: "email", headerName: "Email",flex: 1, disableSelectionOnClick:true, },
    { field: "name", headerName: "Name", flex: 1, disableSelectionOnClick:true, },
    { field: "media", headerName: "Media Report", flex: 1, renderCell: mediaReport, sortable:false, disableSelectionOnClick:true, },
    { field: "uptime", headerName: "Uptime Report", flex: 1, renderCell: uptimeReport, sortable:false, disableSelectionOnClick:true, },
    { field: "audit", headerName: "Audit Logs", flex: 1, renderCell: auditReport, sortable:false, disableSelectionOnClick:true, },
  ];
  const rows = [];
  if (vendors?.vendors) {
    vendors.vendors.forEach((item) => {
      rows.push({
        id: "#" + item._id,
        email: item.email,
        name: item.name,
        media: item,
        uptime: item,
        audit:item
      });
    });
  }

  return (
    <Fragment>
      <AddVendor
        open={open}
        setOpen={setOpen}
        setAlert={setAlert}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        type={type}
        setType={setType}
      />
      <div className="col-12">
        <div className="card">
          <Card.Header className="d-block">
            <Card.Title>
              Trendy Clients Reports
            </Card.Title>
            <Card.Text className="mb-0 subtitle">
              Clients Directly associated with Trendy
            </Card.Text>
          </Card.Header>
          <div className="card-body">
            <div className="w-100">
              <div style={{ display: "flex", height: "100%" }}>
                <DataGrid
                  components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                    Toolbar: CustomToolbar,
                    LoadingOverlay: LinearProgress,
                    Pagination: CustomPagination,
                  }}
                  rows={rows}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  pagination
                  disableSelectionOnClick
                  autoHeight={true}
                  loading={loading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};
export default connect(mapStateToProps)(ClientsReports);
