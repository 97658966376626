import {
  ADD_VENDOR_REQUEST,
  ADD_VENDOR_SUCCESS,
  ADD_VENDOR_FAIL,
  CLEAR_ERRORS,
  GET_VENDOR_REQUEST,
  GET_VENDOR_SUCCESS,
  GET_VENDOR_FAIL,
  VENDOR_REQUEST,
  VENDOR_SUCCESS,
  VENDOR_FAIL,
  VENDOR_LIST_REQUEST,
  VENDOR_LIST_SUCCESS,
  VENDOR_LIST_FAIL,
  UPDATE_VENDOR_REQUEST,
  UPDATE_VENDOR_SUCCESS,
  UPDATE_VENDOR_FAIL,
  GET_VENDOR_REPORT_REQUEST,
  GET_VENDOR_REPORT_SUCCESS,
  GET_VENDOR_REPORT_FAIL,
} from "../Constants/index";
import { BASE_URL } from "../Utils";
import axios from "axios";
export const VendorAction = (token) => async (dispatch) => {
  try {
    dispatch({
      type: VENDOR_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    };
    const { data } = await axios.get(
      `${BASE_URL}admin/vendor`,
      config
    );
    dispatch({ type: VENDOR_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: VENDOR_FAIL,
      payload: err.response,
    });
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

export const addVendor = (formData, token) => async (dispatch) => {
  try {
    dispatch({ type: ADD_VENDOR_REQUEST });
    let link = `${BASE_URL}admin/vendor`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.post(link, JSON.stringify(formData), config);
    dispatch({ type: ADD_VENDOR_SUCCESS, payload: data });
  } catch (err) {
    console.log("error", err.response);
    dispatch({ type: ADD_VENDOR_FAIL, payload: err.response });
  }
};

export function deleteClient(id, token) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.delete(`${BASE_URL}admin/vendor?vendorId=${id}`, config);
}

export const updateVendor = (formData, token) => async (dispatch) => {
  try {
    console.log(formData);
    dispatch({ type: UPDATE_VENDOR_REQUEST });
    let link = `${BASE_URL}admin/vendor`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.put(link, JSON.stringify(formData), config);
    setTimeout(() => {
      dispatch({ type: UPDATE_VENDOR_SUCCESS, payload: data });
    }, 200);
  } catch (err) {
    dispatch({ type: UPDATE_VENDOR_FAIL, payload: err.response });
  }
};

export const getVendor = (id, token) => async (dispatch) => {
  try {
    dispatch({ type: GET_VENDOR_REQUEST });
    let link = `${BASE_URL}admin/vendor/${id}`;

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${token}`,
      },
    };

    const { data } = await axios.get(link, config);
    dispatch({ type: GET_VENDOR_SUCCESS, payload: data });
    // setTimeout(() => {

    // },2000)
  } catch (err) {
    dispatch({ type: GET_VENDOR_FAIL, payload: err.response });
  }
};
export const VendorList = (token, queryString) => async (dispatch) => {
  try {
    dispatch({
      type: VENDOR_LIST_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.get(
      `${BASE_URL}admin/vendor/list?limit=1000${queryString}`,
      config
    );
    dispatch({ type: VENDOR_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: VENDOR_LIST_FAIL,
      payload: err.response,
    });
  }
};
export const orderStatus = async (id, values, token) => {
  try {
    let link = `${BASE_URL}admin/orders/${id}`;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${token}`,
      },
    };
    const { data } = await axios.put(link, values, config);
    console.log("data", data);
    return data;
  } catch (err) {
    return {
      success: false,
      message: err,
    };
  }
};

export const VendorReport = (token, type, queryString) => async (dispatch) => {
  try {
    dispatch({
      type: GET_VENDOR_REPORT_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.get(
      `${BASE_URL}admin/vendor/${type}?${queryString}`,
      config
    );
    dispatch({ type: GET_VENDOR_REPORT_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: GET_VENDOR_REPORT_FAIL,
      payload: err.response,
    });
  }
};
